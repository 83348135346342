import { useQuery } from "@tanstack/react-query";
import { getCurrentUser } from "@/utils/api";
import { useUserStore } from "@/stores/useUserStore";
import { useEffect } from "react";
import axios from "axios";

export function useAuth() {
  const { setUser, clearUser } = useUserStore();
  const token = localStorage.getItem("token");

  const query = useQuery({
    queryKey: ["currentUser"],
    queryFn: getCurrentUser,
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
    retry: 1,
    enabled: !!token,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (query.data) {
      setUser(query.data);
    }
    if (query.error) {
      if (axios.isAxiosError(query.error)) {
        const status = query.error.response?.status;
        if (status === 401 || status === 403) {
          clearUser();
          localStorage.removeItem("token");
        }
      }
    }
  }, [query.data, query.error, setUser, clearUser]);

  return { user: query.data, isLoading: query.isLoading };
}
