import { Controller } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { FormDescription, FormLabel } from "./ui/form";

type TarotFormFieldProps = {
  name: string;
  label: string;
  description?: string;
  control: any; // Adjust type as necessary
  placeholder?: string;
  type?: string; // Add type prop for input type
  isTextarea?: boolean; // New prop to determine if it's a textarea
};

export const TarotFormField = ({
  name,
  label,
  description,
  control,
  placeholder,
  type = "text", // Default to text
  isTextarea = false // Default to false
}: TarotFormFieldProps) => {
  return (
    <div>
      <FormLabel className="text-2xl text-primary-foreground font-bold mb-2 inline-block">
        {label}
      </FormLabel>

      {description && (
        <FormDescription className="pb-2 text-primary-foreground/70 text-sm mb-2">
          {description}
        </FormDescription>
      )}
      <div className="text-primary-foreground">
        <Controller
          name={name}
          control={control}
          render={({ field }) =>
            isTextarea ? (
              <Textarea id={name} placeholder={placeholder} {...field} />
            ) : (
              <Input
                id={name}
                type={type}
                placeholder={placeholder}
                {...field}
              />
            )
          }
        />
      </div>
    </div>
  );
};
