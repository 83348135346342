import { motion, useInView } from "framer-motion";
import { useState } from "react";
import { Textures } from "./Textures";
import { useRef } from "react";
import { Check } from "lucide-react";
import { cn } from "@/lib/utils";
import { PushableButton } from "./PushableButton";
import TornDivider from "./TornDivider";

const tiers = [
  {
    name: "Moonstone Pack",
    id: "tier-moonstone",
    href: "#",
    price: "$8",
    description:
      "Dip your toes into Tarot with personal readings and daily guidance. Perfect for beginners seeking insight.",
    mostPopular: false,
    credits: 111,
    regularPrice: null,
    image: "moonstone.png"
  },
  {
    name: "Amethyst Pack",
    id: "tier-amethyst",
    href: "#",
    price: "$16",
    description:
      "The perfect balance of value for regular readings and deep insights. Our most popular choice.",
    mostPopular: true,
    credits: 333,
    regularPrice: "$30",
    image: "amethyst-2.png"
  },
  {
    name: "Sapphire Pack",
    id: "tier-sapphire",
    href: "#",
    price: "$32",
    regularPrice: "$60",
    description:
      "Ideal for long-term Tarot exploration and guidance. Maximum savings for your extended practice.",
    mostPopular: false,
    credits: 888,
    image: "sapphire-2.png"
  }
];

const PricingSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  return (
    <div ref={ref} id="pick-your-guide" className="bg-primary relative">
      <Textures type="paper-1" />
      <TornDivider className="bottom-36" />
      <motion.div className="mx-auto max-w-3xl text-primary-foreground text-center pt-24 mt-16 relative">
        <div className="w-full flex justify-center mt-12">
          {/* Rotating moon in a loop */}
          <motion.img
            src="/gemstones/diamond.png"
            alt="Diamond"
            initial={{ opacity: 0, y: 0 }}
            animate={
              isInView
                ? {
                    opacity: 1,
                    y: [0, 10, 0]
                  }
                : { opacity: 0, y: -10 }
            }
            transition={{
              opacity: { duration: 1, delay: 1 },
              y: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 15,
                ease: "easeInOut"
              }
            }}
            className="w-16 h-16 object-contain -top-6 relative origin-center"
          />
        </div>
        <h2 className="text-3xl text font-bold tracking-tight leading-loose text-primary-foreground sm:text-4xl sm:leading-[3.5rem]">
          Pay As You Go, <br />
          No Subscription
        </h2>
        <p className="mt-2 text-lg leading-8 text-primary-foreground font-rosarivo mx-auto max-w-[450px]">
          Get Tarot readings and guidance on your terms. Purchase credits and
          use them whenever you need. No monthly fees, no commitments.
        </p>
      </motion.div>
      <div className="mx-auto max-w-7xl">
        <div className="isolate mx-auto pt-24 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 relative">
          {tiers.map((tier, tierIdx) => (
            <>
              <motion.div
                style={{
                  transformStyle: "preserve-3d"
                }}
                animate={{
                  transform:
                    tier.name === "Moonstone Pack"
                      ? "perspective(1000px) rotate3d(-1, -1, 0, -12deg)"
                      : tier.name === "Amethyst Pack"
                      ? "perspective(1000px) rotate3d(1, 0, 0, 0deg)"
                      : tier.name === "Sapphire Pack"
                      ? "perspective(1000px) rotate3d(1, -1, 0, 12deg)"
                      : "",
                  scale: tier.mostPopular ? 1 : 0.5
                }}
                transition={{
                  duration: 1,
                  ease: "easeOut"
                }}
                key={tier.id}
                className={cn(
                  tier.mostPopular ? "lg:z-10 shadow-2xl" : "lg:my-8 shadow-lg",
                  tierIdx === 0 ? "lg:rounded-r-none" : "",
                  tierIdx === tiers.length - 1 ? "lg:rounded-l-none" : "",
                  tier.name === "Moonstone Pack"
                    ? "left-5 bg-primary-dark"
                    : tier.name === "Amethyst Pack"
                    ? "bg-primary"
                    : tier.name === "Sapphire Pack"
                    ? "right-5 bg-primary-dark"
                    : "",
                  // "flex flex-col justify-between rounded-3xl bg-primary p-8 ring-1 ring-gray-200 xl:p-10 relative",
                  "flex flex-col justify-between rounded-2xl p-8 relative border border-primary-foreground/20"
                )}
              >
                <div className="absolute inset-x-4 inset-y-4 border-2 border-primary-foreground/20 rounded-xl"></div>
                <Textures type="paper-1" />
                {tier.mostPopular ? (
                  <>
                    <span className="rounded-full bg-rose-600 px-2.5 py-1 text-xs/5 font-semibold text-rose-100 absolute right-8 -top-3 border border-primary-foreground/20">
                      <span className="top-[1px] relative">Most popular</span>
                    </span>
                  </>
                ) : null}
                {/* <div className="absolute inset-x-0 flex items-center justify-center">
                <div className="flex items-center justify-center -top-32 absolute bg-primary border-2 border-primary-foreground/20 rounded-full gap-x-4 h-32 w-32 -z-10" />
                <div
                  className={cn(
                    "aspect-square absolute bg-contain bg-center bg-no-repeat -mt-36",
                    tier.mostPopular ? "w-32 h-32" : "w-24 h-24"
                  )}
                  style={{
                    backgroundImage: `url('/gemstones/${tier.image}')`
                  }}
                />
              </div> */}
                <div className="relative z-10">
                  <div
                    className={cn(
                      "flex items-center justify-center mb-4 -mt-16",
                      tier.name === "Moonstone Pack"
                        ? "-mt-16"
                        : tier.name === "Amethyst Pack"
                        ? "-mt-40 mb-10"
                        : tier.name === "Sapphire Pack"
                        ? "-mt-32"
                        : ""
                    )}
                  >
                    {tier.mostPopular ? (
                      <div className="absolute blur-2xl">
                        <motion.div
                          animate={{
                            opacity: [1, 0.6, 1],
                            scale: [1.1, 1.25, 1.1]
                          }}
                          transition={{
                            scale: {
                              repeat: Infinity,
                              repeatType: "loop",
                              duration: 5,
                              ease: "easeInOut"
                            },
                            opacity: {
                              repeat: Infinity,
                              repeatType: "loop",
                              duration: 5,
                              ease: "easeInOut"
                            }
                          }}
                          className={cn(
                            "aspect-square bg-contain bg-center bg-no-repeat origin-center",
                            "w-48 h-48"
                          )}
                          style={{
                            backgroundImage: `url('/gemstones/${tier.image}')`
                          }}
                        />
                      </div>
                    ) : null}
                    <motion.div
                      animate={{
                        rotate: tier.mostPopular ? [0, 2, 0, -2, 0] : 0,
                        scale: tier.mostPopular ? [1, 1.05, 1] : 1,
                        y: [0, 10, 0]
                      }}
                      transition={{
                        rotate: {
                          repeat: Infinity,
                          repeatType: "loop",
                          duration: 15,
                          ease: "easeInOut"
                        },
                        scale: {
                          repeat: Infinity,
                          repeatType: "loop",
                          duration: 15,
                          ease: "easeInOut"
                        },
                        y: {
                          repeat: Infinity,
                          repeatType: "loop",
                          duration: 15,
                          ease: "easeInOut",
                          delay: 5 * tierIdx
                        }
                      }}
                      className={cn(
                        "aspect-square bg-contain bg-center bg-no-repeat origin-center",
                        tier.name === "Moonstone Pack"
                          ? "w-24 h-24"
                          : tier.name === "Amethyst Pack"
                          ? "w-48 h-48"
                          : tier.name === "Sapphire Pack"
                          ? "w-40 h-40"
                          : ""
                      )}
                      style={{
                        backgroundImage: `url('/gemstones/${tier.image}')`
                      }}
                    />
                  </div>
                  <h3
                    id={tier.id}
                    className={cn(
                      "text-primary-foreground relative z-10",
                      "font-semibold mb-2 text-center",
                      tier.mostPopular ? "text-3xl" : "text-2xl"
                    )}
                  >
                    {tier.name}
                  </h3>

                  <p className="mt-4 text-sm/6 text-primary-foreground text-center">
                    {tier.description}
                  </p>
                  <div className="flex items-center justify-center">
                    <ul
                      role="list"
                      className="mt-8 space-y-3 text-sm/6 text-primary-foreground"
                    >
                      {["1 Credit / Tarot Reading", "1 Credit / Message"].map(
                        (feature) => (
                          <li key={feature} className="flex gap-x-3">
                            <Check
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-rose-600"
                            />
                            {feature}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-4 mt-5 mb-4 relative">
                    {/* Rotating Background Blob */}
                    <div className="absolute inset-0 flex items-center justify-center blur">
                      <motion.div
                        className={cn(
                          "-z-10 absolute",
                          tier.mostPopular ? "w-52 h-52" : "w-32 h-32",
                          tier.name === "Moonstone Pack"
                            ? "bg-gray-600/75"
                            : tier.name === "Amethyst Pack"
                            ? "bg-purple-700/70"
                            : tier.name === "Sapphire Pack"
                            ? "bg-indigo-600"
                            : ""
                        )}
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: [0.1, 0.2, 0.1],
                          scale: [0.7, 0.8, 0.7],
                          borderRadius: [
                            "30% 70% 70% 30% / 30% 30% 70% 70%",
                            "60% 40% 30% 70% / 60% 30% 70% 40%",
                            "30% 60% 70% 40% / 50% 60% 30% 60%",
                            "30% 70% 70% 30% / 30% 30% 70% 70%"
                          ]
                        }}
                        transition={{
                          duration: 16,
                          repeat: Infinity,
                          repeatType: "mirror",
                          ease: "easeInOut",
                          delay: tierIdx * 1.6,
                          opacity: {
                            repeat: 1,
                            duration: 8
                          }
                        }}
                      />
                    </div>
                    <div className="text-primary-foreground text-4xl font-semibold relative top-1 drop-shadow-xl">
                      {tier.credits}
                      <span className="text-primary-foreground text-3xl ml-2">
                        Credits
                      </span>
                    </div>
                    <div className="flex items-baseline gap-x-2 relative">
                      <span className="text-3xl font-semibold text-primary-foreground">
                        For
                      </span>
                      <span className="text-4xl font-semibold tracking-tight text-primary-foreground">
                        {tier.price}
                      </span>
                      {/* <span className="text-4xl font-semibold tracking-tight text-primary-foreground flex items-center relative opacity-50 absolute">
                        <span className="w-[120%] -left-[10%] h-[2px] bg-primary-foreground block absolute" />
                        {tier.regularPrice}
                      </span> */}
                    </div>
                    {tier.name === "Amethyst Pack" ||
                    tier.name === "Sapphire Pack" ? (
                      <div className="flex items-center justify-center">
                        <span className="rounded-full bg-primary-dark px-2.5 py-1 text-xs/5 font-semibold text-rose-700/75 border border-rose-500/50">
                          <span className="top-[1px] relative">
                            {tier.name === "Amethyst Pack"
                              ? "Save 33%"
                              : "Save 50%"}
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                  {/* <p className="mt-6 flex flex-col items-center justify-center gap-2 mb-8">
                    <span className="text-4xl font-semibold tracking-tight text-primary-foreground">
                      {tier.price}
                    </span>
                  </p> */}
                  {/* <ul
                  role="list"
                  className="mt-8 space-y-3 text-sm/6 text-gray-600"
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <Check
                        aria-hidden="true"
                        className="h-6 w-5 flex-none text-rose-600"
                      />
                      {feature}
                    </li>
                  ))}
                </ul> */}
                </div>
                {/* <PushableButton text={"Buy"} className={cn("mx-auto")} /> */}
                {/* <a
                href={tier.href}
                aria-describedby={tier.id}
                className={cn(
                  tier.mostPopular
                    ? "bg-rose-600 text-white shadow-sm hover:bg-rose-500"
                    : "text-rose-600 ring-1 ring-inset ring-rose-200 hover:ring-rose-300",
                  "mt-8 block rounded-md relative z-10 px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                )}
              >
                Buy
              </a> */}
              </motion.div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
