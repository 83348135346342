import { cn } from "@/lib/utils";

interface LogoProps {
  textSize?: "large" | "small";
}

const Logo = ({ textSize = "large" }: LogoProps) => (
  <a href="/" className="-m-1.5 p-1.5 flex space-x-4 items-center">
    <img
      className="object-contain"
      src="/logo.png"
      width={40}
      height={40}
      alt="Logo"
    />
    <span
      className={cn(
        "font-bold relative text-primary-foreground",
        textSize === "large" ? "text-base md:text-2xl" : "text-lg"
      )}
    >
      Your Unique Tarot
    </span>
    <span className="sr-only">Your Unique Tarot</span>
  </a>
);

export default Logo;
