import { motion, Variants, AnimatePresence } from "framer-motion";

const gradientVariants: Variants = {
  initial: {},
  animate: {
    clipPath: [
      "polygon(60% 20%, 80% 10%, 95% 35%, 90% 80%, 60% 90%, 30% 85%, 10% 50%, 15% 20%, 40% 15%)",
      "polygon(65% 15%, 85% 25%, 90% 45%, 85% 75%, 55% 85%, 25% 80%, 15% 55%, 20% 25%, 45% 20%)",
      "polygon(55% 25%, 75% 15%, 90% 40%, 85% 70%, 50% 85%, 20% 75%, 10% 45%, 25% 20%, 50% 25%)"
    ],
    rotate: [0, 10, -5, 0],
    transition: {
      duration: 20,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse"
    }
  }
};

const fadeInVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 1.5 // Adjust this value to make the fade slower or faster
    }
  }
};

interface BlurBackgroundProps {
  size?: "large" | "small";
  rotate?: number;
}

const BlurBackground: React.FC<BlurBackgroundProps> = ({
  size = "large",
  rotate = 0
}) => {
  return (
    <AnimatePresence>
      <motion.div
        className="blur-lg transform-gpu pointer-events-none relative z-0"
        style={{ rotate: `${rotate}deg` }}
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
      >
        <motion.div
          aria-hidden="true"
          className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
          variants={gradientVariants}
          initial="initial"
          animate="animate"
        >
          <motion.div
            className="aspect-[1313/771] ml-[40vw] w-[50vw] bg-gradient-to-tr from-pink-400 to-rose-600 mt-[40vh]"
            variants={gradientVariants}
            initial="initial"
            animate="animate"
          />
        </motion.div>
        {size === "large" && (
          <motion.div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden opacity-25 blur-3xl xl:justify-end"
            variants={gradientVariants}
            initial="initial"
            animate="animate"
          >
            <motion.div
              className="aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr  from-rose-500 via-orange-400 to-pink-400 xl:ml-0 xl:mr-[calc(50%-12rem)]"
              variants={gradientVariants}
              initial="initial"
              animate="animate"
            />
          </motion.div>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default BlurBackground;
