import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Card as CardType } from "@/types/Card";
import { useWindowSize } from "@/hooks/useWindowSize";
import { cn } from "@/lib/utils";
import cardBack from "/card-back.jpg";

interface CardProps {
  card: CardType;
  isRevealed: boolean;
  setIsRevealed: (isRevealed: boolean) => void;
  inverseHoverEffect?: boolean;
}

const Card: React.FC<CardProps> = ({
  card,
  isRevealed,
  setIsRevealed,
  inverseHoverEffect = false
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowSize();
  const scale = getResponsiveScale(windowWidth);

  // Add parallax effect
  useEffect(() => {
    if (isRevealed) {
      const handleMouseMove = (e: MouseEvent) => {
        if (cardRef.current) {
          const rect = cardRef.current.getBoundingClientRect();
          const cardCenterX = rect.left + rect.width / 2;
          const cardCenterY = rect.top + rect.height / 2;

          const mouseX = (e.clientX - cardCenterX) / (rect.width / 2);
          const mouseY = (e.clientY - cardCenterY) / (rect.height / 2);

          const tiltX = inverseHoverEffect ? -mouseY * 0.5 : mouseY * 0.5;
          const tiltY = inverseHoverEffect ? mouseX * 1.5 : -mouseX * 1.5;

          cardRef.current.style.transform = `
            perspective(1000px)
            rotateX(${tiltX}deg)
            rotateY(${tiltY}deg)
            scale(${scale})
          `;
        }
      };

      window.addEventListener("mousemove", handleMouseMove);

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [isRevealed, scale, inverseHoverEffect]);

  const handleCardClick = () => {
    if (!isRevealed) {
      setIsRevealed(true);
    }
  };

  return (
    <motion.div
      ref={cardRef}
      className={cn(
        "relative w-[200px] overflow-hidden aspect-[400/700] rounded-lg lg:rounded-2xl p-3 pb-[46px] bg-[#f4e7d8] border border-black border-opacity-40 pointer-events-auto",
        {
          "cursor-pointer": !isRevealed
        }
      )}
      style={{
        transformStyle: "preserve-3d",
        transition: isRevealed ? "transform 0.1s ease-out" : undefined
      }}
      onClick={handleCardClick}
    >
      <div
        className="relative w-full h-full"
        style={{ transformStyle: "preserve-3d" }}
      >
        <CardNumber cardNumber={card.cardNumber} isRevealed={isRevealed} />
        <CardBack isRevealed={isRevealed} />
        <CardFront card={card} isRevealed={isRevealed} />
        <CardTexture />
      </div>
    </motion.div>
  );
};

const CardNumber: React.FC<{ cardNumber: number; isRevealed: boolean }> = ({
  cardNumber,
  isRevealed
}) => (
  <motion.div
    animate={{
      opacity: isRevealed ? 1 : 0,
      transition: { delay: 0.15 }
    }}
    className="flex items-center absolute w-full justify-center z-50 top-0"
  >
    <span className="absolute flex items-center justify-center font-rosarivo text-xs border border-t-0 border-black/60 bg-[#f4e7d8] z-50 font-left-0 mx-4 w-8 h-4 mt-4 rounded-br-full rounded-bl-full">
      <span className="z-10 text-xs -mt-2">{cardNumber}</span>
    </span>
  </motion.div>
);

const CardBack: React.FC<{ isRevealed: boolean }> = ({ isRevealed }) => (
  <motion.div
    className="absolute w-full h-[calc(100%+50px-1rem)] backface-hidden pointer-events-none border border-black border-opacity-70 rounded-md"
    style={{ backfaceVisibility: "hidden" }}
    animate={{ opacity: isRevealed ? 0 : 1 }}
  >
    <img
      src={cardBack}
      className="object-cover w-full h-full pointer-events-none rounded-md"
      alt="Card Back"
    />
  </motion.div>
);

const CardFront: React.FC<{ card: CardType; isRevealed: boolean }> = ({
  card,
  isRevealed
}) => {
  // Get mouse position and percentage of the screen
  // Don't use any libraries for this, just plain JS
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const { width } = useWindowSize();
  const scale = getResponsiveScale(width);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      // Calculate the percentage of the screen
      const percentageX = e.clientX / window.innerWidth;
      const percentageY = e.clientY / window.innerHeight;

      setOffset({ x: percentageX, y: percentageY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <motion.div
      className="absolute w-full h-full backface-hidden pointer-events-none"
      style={{ backfaceVisibility: "hidden" }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1 }}
    >
      <div className="overflow-hidden relative w-full h-full rounded-t-md">
        <motion.img
          src="/rays.jpg"
          style={{
            transform: `translate(${(offset.x - 0.5) * 10}%, ${
              (offset.y - 0.5) * 10
            }%) scale(${scale})`
          }}
          className="absolute inset-0 mix-blend-color-dodge object-cover pointer-events-none opacity-30 rounded-md"
        />
        <img
          src={card.imageUrl || ""}
          className="object-cover rounded-md rounded-b-none w-full h-full pointer-events-none border border-black/40"
          alt={card.name}
        />
      </div>
      <div className="relative inset-x-0 bottom-0 bg-[#f4e7d8] border border-t-0 text-center border-black/40 rounded-b-md p-1">
        <span className="font-rosarivo text-xs">{card.name}</span>
      </div>
    </motion.div>
  );
};

const CardTexture: React.FC = () => (
  <div className="absolute z-10 inset-0 bg-[url('/texture.jpg')] bg-cover mix-blend-multiply pointer-events-none"></div>
);

const getResponsiveScale = (windowWidth: number) => {
  if (windowWidth < 640) return 1.05; // sm
  if (windowWidth < 768) return 1.1; // md
  if (windowWidth < 1024) return 1.15; // lg
  if (windowWidth < 1280) return 1.2; // xl
  if (windowWidth < 1536) return 1.25; // 2xl
  return 1.3; // larger than 2xl
};

export default Card;
