import Layout from "@/components/layout";
import { fetchAllReadings } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Card as CardType } from "@/types/Card";
import { CardLoader } from "@/components/CardLoader";
import { Card } from "./ReadingDetail";

interface Reading {
  id: number;
  type: "daily" | "custom";
  card: CardType;
  createdAt: string;
}

interface ReadingsResponse {
  readings: Reading[];
  pagination: {
    total: number;
    limit: number;
    offset: number;
  };
}

const ReadingOverview: React.FC = () => {
  const { data, isLoading, isFetching } = useQuery<ReadingsResponse>({
    queryKey: ["readings"],
    queryFn: async () => {
      const response = await fetchAllReadings();
      return response as ReadingsResponse;
    }
  });

  if (isLoading && !isFetching) {
    return (
      <Layout>
        <CardLoader loadingText="Loading your readings..." />
      </Layout>
    );
  }

  return (
    <Layout disablePadding>
      <div className="relative h-full md:pb-8">
        <div>
          <h1 className="text-3xl font-bold text-orange-950 mb-6 text-center">
            Your Readings
          </h1>
          <hr className="border-orange-950/10 mb-10" />

          {data?.readings.length === 0 ? (
            <div className="text-center text-gray-600 mt-8">
              <p>You haven't done any readings yet.</p>
            </div>
          ) : (
            <div className="px-4 sm:px-8 h-full pb-8">
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8">
                {data?.readings.map((reading) => (
                  <Link
                    key={reading.id}
                    to={`/readings/${reading.id}`}
                    className="block group"
                  >
                    <div className="flex items-center justify-center">
                      <span className="text-primary-foreground text-xl text-center inline-block pt-2 pb-1 px-6 border border-primary-darker mx-auto bg-background-dark rounded-t-lg">
                        {format(new Date(reading.createdAt), "MMM d")}
                      </span>
                    </div>
                    <Card card={reading.card} />
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ReadingOverview;
