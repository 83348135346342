import refund from "./../../policies/refund.md?raw";
import privacy from "./../../policies/privacy.md?raw";
import tos from "./../../policies/terms-of-service.md?raw";
import ReactMarkdown from "react-markdown";
import Footer from "@/components/footer";

const policies = {
  refund,
  privacy: privacy,
  tos: tos
} as const;

// Create a type of the differnet keys of policies

const Policy = ({ policy }: { policy: keyof typeof policies }) => {
  return (
    <>
      <div className="px-6 py-32 lg:px-8 font-rosarivo text-gray-800">
        <div className="mx-auto max-w-3xl prose lg:prose-xl">
          <ReactMarkdown>{policies[policy]}</ReactMarkdown>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Policy;
