import { motion, useInView } from "framer-motion";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion";
import { useRef } from "react";

const faqs = [
  {
    id: "how-credits-work",
    question: "How do credits work?",
    answer:
      "Credits are your currency for interactions on YourUniqueTarot. One credit equals one action, whether that's drawing a card or sending a message in a reading. You can start with 5 free credits by verifying your email, and purchase more credits through our various packages when needed."
  },
  {
    id: "accuracy-ai-readings",
    question: "How accurate are AI-powered tarot readings?",
    answer:
      "Our AI is trained on centuries of tarot wisdom and interpretation, providing insights that are as meaningful as traditional readings. While the AI assists in interpretation, the true power lies in how the readings resonate with your personal situation and intuition."
  },
  {
    id: "beginner-friendly",
    question: "Is this suitable for beginners?",
    answer:
      "Absolutely! YourUniqueTarot is designed to be accessible for everyone, from complete beginners to experienced readers. Our platform includes educational resources and guided interpretations to help you understand the meanings and symbolism of each card."
  },
  {
    id: "privacy-security",
    question: "How secure are my readings and conversations?",
    answer:
      "We take your privacy seriously. All readings and conversations are encrypted and stored securely. Your personal information and reading history are never shared with third parties."
  },
  {
    id: "refund-policy",
    question: "What's your refund policy?",
    answer:
      "Due to the instant digital nature of our services and credits, we do not offer refunds once credits are purchased. Please choose your credit package carefully."
  },
  {
    id: "difference-traditional",
    question: "How is this different from traditional tarot readings?",
    answer:
      "YourUniqueTarot combines the ancient wisdom of tarot with modern technology. While maintaining the essence of traditional tarot, we enhance the experience with AI-powered insights, digital convenience, and the ability to record and review your readings over time."
  },
  {
    id: "credit-expiration",
    question: "Do credits expire?",
    answer:
      "No, your purchased credits never expire. You can use them at your own pace, whenever you feel the need for guidance or insight."
  }
];

const FAQSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  return (
    <div className="max-w-7xl mx-auto mt-32">
      <motion.div className="mx-auto max-w-3xl text-primary-foreground text-center pt-24 mt-16 relative">
        <div className="w-full flex justify-center mt-12">
          {/* Rotating moon in a loop */}
          <motion.img
            src="/card-assets/star-0.png"
            alt="Diamond"
            initial={{ opacity: 0, y: 0, rotate: 0 }}
            ref={ref}
            animate={
              isInView
                ? {
                    opacity: 1,
                    y: [0, 10, 0],
                    rotate: [0, -360, 0]
                  }
                : { opacity: 0, y: -10, rotate: 0 }
            }
            transition={{
              opacity: { duration: 1, delay: 1 },
              rotate: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 60,
                ease: "easeInOut"
              }
            }}
            className="w-16 h-16 object-contain -top-6 relative origin-center"
          />
        </div>
        <h2 className="text-3xl text font-bold tracking-tight leading-loose text-primary-foreground sm:text-4xl sm:leading-[3.5rem]">
          Frequently Asked Questions
        </h2>
        <p className="mt-2 text-lg leading-8 text-primary-foreground font-rosarivo mx-auto max-w-[450px]">
          Get Tarot readings and guidance on your terms. Purchase credits and
          use them whenever you need. No monthly fees, no commitments.
        </p>
      </motion.div>
      <div className="max-w-3xl mx-auto mt-12">
        <Accordion type="single" collapsible>
          {faqs.map((faq) => (
            <AccordionItem value={faq.id}>
              <AccordionTrigger className="py-6">
                {faq.question}
              </AccordionTrigger>
              <AccordionContent>{faq.answer}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQSection;
