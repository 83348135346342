import Layout from "@/components/layout";
import { BookOpenText, Flame, Flower, HandHeart } from "lucide-react";
import { useState, useEffect } from "react";
import { updatePreferredGuide } from "@/utils/api";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { useUserStore } from "@/stores/useUserStore";

export const guides = [
  {
    id: 1,
    name: "Luna",
    icon: Flame,
    audio: "/luna.mp3",
    description:
      "A wise, sharp-tongued tarot reader with over 50 years of experience",
    bulletPoints: [
      "Seasoned tarot reader with a wealth of life experience",
      "Direct, no-nonsense approach",
      "Offers hard truths and practical advice",
      "Skilled at revealing hidden truths",
      "Best for those seeking unfiltered insights"
    ],
    image: "/guides/swords.png",
    slogan: "Truth, like a sword, cuts through confusion to reveal clarity"
  },
  {
    id: 2,
    name: "Sage",
    icon: HandHeart,
    audio: "/sage.mp3",
    description:
      "An intuitive empath and tarot reader with a background in psychology",
    bulletPoints: [
      "Intuitive empath with psychology background",
      "Focuses on personal growth and emotional healing",
      "Uses nature and celestial metaphors",
      "Soft-spoken but direct communication style",
      "Ideal for those seeking supportive guidance"
    ],
    image: "/guides/cups.png",
    slogan: "Every card holds a story that can speak to your heart"
  },
  {
    id: 3,
    name: "Thomas",
    icon: BookOpenText,
    audio: "/thomas.mp3",
    description: "A former literature professor turned tarot reader",
    bulletPoints: [
      "Former student of Carl Jung and James Hillman",
      "Blends archetypes, symbolism, and depth psychology with tarot",
      "Incorporates literature and mythology into readings",
      "Explores the soul's journey through mythological patterns",
      "Perfect for those interested in archetypal and symbolic interpretations"
    ],
    image: "/guides/wands.png",
    slogan: "Where myths and symbols light the path to understanding"
  },
  {
    id: 4,
    name: "Maya",
    icon: Flower,
    audio: "/maya.mp3",
    description:
      "A grounded spiritual guide with expertise in practical manifestation",
    bulletPoints: [
      "Life coach and holistic wellness practitioner",
      "Specializes in career, health, and financial guidance",
      "Masters real-world applications of spiritual wisdom",
      "Balances material success with spiritual growth",
      "Perfect for those seeking concrete, actionable solutions"
    ],
    image: "/guides/pents.png",
    slogan: "Where potential meets ground, possibilities can grow"
  }
];

const Guides = () => {
  const user = useUserStore((state) => state.user);
  const [selectedGuideId, setSelectedGuideId] = useState(
    user?.preferredGuideId || 1
  );
  const { toast } = useToast();

  useEffect(() => {
    if (user?.preferredGuideId) {
      setSelectedGuideId(user.preferredGuideId);
    }
  }, [user?.preferredGuideId]);

  const updateGuideMutation = useMutation({
    mutationFn: updatePreferredGuide,
    onSuccess: () => {
      toast({
        title: "Guide Updated",
        description: "Your preferred guide has been updated successfully.",
        duration: 3000
      });
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to update your preferred guide. Please try again.",
        variant: "destructive",
        duration: 3000
      });
    }
  });

  const handleGuideSelect = (guideId: number) => {
    setSelectedGuideId(guideId);
    updateGuideMutation.mutate(guideId);
  };

  return (
    <Layout>
      <h1 className="text-3xl text-center font-semibold text-primary-foreground my-6">
        Pick Your Guide
      </h1>
      <hr className="border-primary-foreground/20 mb-10" />

      <div className="flex flex-col gap-4 items-center w-full max-w-2xl mx-auto mb-8">
        {guides.map((guide) => (
          <div
            className={cn(
              "flex flex-col gap-3 p-6 border rounded-lg w-full transition-all duration-400 ease-in-out cursor-pointer relative",
              selectedGuideId === guide.id
                ? "border-rose-600 bg-rose-50/30 shadow"
                : "border-primary-foreground/20 bg-rose-50/0 hover:border-rose-600/30"
            )}
            key={guide.id}
            onClick={() => handleGuideSelect(guide.id)}
          >
            <h3 className="text-2xl font-semibold text-primary-foreground">
              {guide.name}
            </h3>
            <p className="italic text-primary-foreground">
              {guide.description}
            </p>
            <ul className="list-disc list-inside space-y-1">
              {guide.bulletPoints.map((point) => (
                <li
                  key={point}
                  className="leading-relaxed text-primary-foreground"
                >
                  {point}
                </li>
              ))}
            </ul>
            <guide.icon className="w-40 h-40 rotate-[20deg] text-rose-600 opacity-5 absolute right-10 top-14" />
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Guides;
