import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { Textures } from "./Textures";
import TornDivider from "./TornDivider";

const FeaturesSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  return (
    <div ref={ref} id="pick-your-guide" className="relative">
      <Textures type="paper-1" className="bottom-0" />
      <TornDivider className="bottom-16" />
      <motion.div className="mx-auto max-w-3xl text-primary-foreground text-center pt-24 mt-16 relative">
        <div className="w-full flex justify-center mt-12">
          <motion.img
            src="/card-assets/star-0.png"
            alt="Star"
            initial={{ opacity: 0, y: 0 }}
            animate={
              isInView
                ? {
                    opacity: 1,
                    y: [0, 10, 0]
                  }
                : { opacity: 0, y: -10 }
            }
            transition={{
              opacity: { duration: 1, delay: 1 },
              y: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 15,
                ease: "easeInOut"
              }
            }}
            className="w-16 h-16 object-contain -top-6 relative origin-center"
          />
        </div>
        <h2 className="text-3xl text font-bold tracking-tight leading-loose text-primary-foreground sm:text-4xl sm:leading-[3.5rem]">
          Unique Features
        </h2>
        <p className="mt-2 text-lg leading-8 text-primary-foreground font-rosarivo mx-auto max-w-[450px]">
          Get Tarot readings and guidance on your terms. Purchase credits and
          use them whenever you need. No monthly fees, no commitments.
        </p>
      </motion.div>
      <BentoGrid />
    </div>
  );
};

const BentoGrid = () => {
  return (
    <div className="mx-auto max-w-7xl">
      <div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
        <div className="relative lg:row-span-2">
          <div className="absolute inset-px rounded-lg bg-primary lg:rounded-l-[2rem]">
            <Textures type="paper-1" />
          </div>
          <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
            <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
              <p className="mt-2 text-2xl font-medium tracking-tight text-primary-foreground max-lg:text-center">
                Daily Tarot Readings
              </p>
              <p className="mt-2 max-w-lg text-base text-primary-foreground-muted max-lg:text-center">
                Write here that the user can use their own deck of cards for
                their drawings, to truly make it their own.
              </p>
            </div>
            <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
              <div className="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] border-x-[3cqw] border-t-[3cqw] border-gray-700 bg-gray-900 shadow-2xl">
                <img
                  className="size-full object-cover object-top"
                  src="https://tailwindui.com/plus/img/component-images/bento-03-mobile-friendly.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div>
        </div>
        <div className="relative max-lg:row-start-1">
          <div className="absolute inset-px rounded-lg bg-primary max-lg:rounded-t-[2rem]">
            <Textures type="paper-1" />
          </div>
          <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
            <div className="px-8 pt-8 sm:px-10 sm:pt-10">
              <p className="mt-2 text-2xl font-medium tracking-tight text-primary-foreground max-lg:text-center">
                Use Your Personal Deck
              </p>
              <p className="mt-2 max-w-lg text-base text-primary-foreground-muted max-lg:text-center">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit maiores
                impedit.
              </p>
            </div>
            <div className="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2">
              {/* <img
                className="w-full max-lg:max-w-xs"
                src="https://tailwindui.com/plus/img/component-images/bento-03-performance.png"
                alt=""
              /> */}
            </div>
          </div>
          <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"></div>
        </div>
        <div className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
          <div className="absolute inset-px rounded-lg bg-primary">
            <Textures type="paper-1" />
          </div>
          <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
            <div className="px-8 pt-8 sm:px-10 sm:pt-10">
              <p className="mt-2 text-2xl font-medium tracking-tight text-primary-foreground max-lg:text-center">
                Secure Readings
              </p>
              <p className="mt-2 max-w-lg text-base text-primary-foreground-muted max-lg:text-center">
                Morbi viverra dui mi arcu sed. Tellus semper adipiscing
                suspendisse semper morbi.
              </p>
            </div>
            <div className="flex flex-1 items-center justify-center [container-type:inline-size] max-lg:py-6 lg:pb-2">
              <img
                className="h-[min(152px,40cqw)] object-cover bg-blend-darken bg-primary-dark"
                // src="/card-assets/lock.png"
                alt=""
              />
            </div>
          </div>
          <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
        </div>
        <div className="relative lg:row-span-2">
          <div className="absolute inset-px rounded-lg bg-primary max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]">
            <Textures type="paper-1" />
          </div>
          <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
            <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
              <span className="rounded-full bg-primary-dark px-2.5 py-1 text-xs/5 font-semibold text-rose-700/75 border border-rose-500/50 absolute top-4 right-4">
                <span className="top-[1px] font-semibold relative">
                  Coming Soon
                </span>
              </span>
              <p className="mt-2 text-2xl font-medium tracking-tight text-primary-foreground max-lg:text-center">
                Spreads and Year Cards
              </p>
              <p className="mt-2 max-w-lg text-base text-primary-foreground-muted max-lg:text-center">
                Sit quis amet rutrum tellus ullamcorper ultricies libero dolor
                eget sem sodales gravida.
              </p>
            </div>
            <div className="relative min-h-[30rem] w-full grow">
              <div className="absolute bottom-0 left-10 right-0 top-10 overflow-hidden rounded-tl-xl bg-gray-900 shadow-2xl">
                <div className="flex bg-gray-800/40 ring-1 ring-white/5">
                  <div className="-mb-px flex text-base font-medium text-gray-400">
                    <div
                      className="border-b border-r border-b-white/20 border-r-white/10 bg-primary/5 px-4 py-2 tex
                    <Texturest-white"
                    >
                      NotificationSetting.jsx
                    </div>
                    <div className="border-r border-gray-600/10 px-4 py-2">
                      App.jsx
                    </div>
                  </div>
                </div>
                <div className="px-6 pb-14 pt-6">{/* Your code example */}</div>
              </div>
            </div>
          </div>
          <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
        </div>
      </div>
      {/* <div className="relative mt-8 rounded-2xl bg-gradient-to-b from-primary to-primary w-full p-6 shadow ring-1 ring-black/5 ">
        <Textures type="paper-1" />
        <h3 className="text-2xl font-medium tracking-tight text-primary-foreground text-center relative">
          Try it for free · Get 5 free credits
        </h3>
      </div> */}
    </div>
  );
};

export default FeaturesSection;
