import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  checkIfDailyReadingExists,
  createReading,
  fetchTarotCards,
  queryClient
} from "@/utils/api";
import Layout from "@/components/layout";
import { motion, AnimatePresence } from "framer-motion";
import { Card as CardType } from "@/types/Card";
import { cn } from "@/lib/utils";
import cardBack from "/card-back.jpg";
import { PushableButton } from "@/components/PushableButton";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "@/hooks/useWindowSize";
import { Reading } from "@/types/api";
import { toast } from "@/components/ui/use-toast";
import { CardLoader } from "@/components/CardLoader";
import { CardSelectionDialog } from "@/components/CardSelectionDialog";

const useHoverState = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [randomLift] = useState(() => 50 + Math.random() * 40); // Random lift between 30-45px

  return {
    hoveredIndex,
    setHoveredIndex,
    randomLift
  };
};

const DailyDraw: React.FC = () => {
  const {
    data: tarotCards,
    isLoading: isTarotCardsLoading,
    isFetching: isTarotCardsFetching
  } = useQuery({
    queryKey: ["tarotCards"],
    queryFn: fetchTarotCards
  });

  const {
    data: dailyReading,
    isLoading: isDailyReadingLoading,
    isFetching: isDailyReadingFetching
  } = useQuery<Reading | null, Error>({
    queryKey: ["dailyReading"],
    queryFn: checkIfDailyReadingExists,
    staleTime: 0, // 0 seconds
    gcTime: 1000 * 60 * 60, // 1 hour
    refetchOnMount: "always"
  });

  const { width: windowWidth } = useWindowSize();
  const [cards, setCards] = useState<CardType[]>([]);
  const [draggedCards, setDraggedCards] = useState<number[]>([]);
  const [isDoneDragging, setIsDoneDragging] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const [readingId, setReadingId] = useState<string | null>(null);
  const [isCardSelectionOpen, setIsCardSelectionOpen] = useState(false);
  const hoverState = useHoverState();
  const [isInitialAnimationComplete, setIsInitialAnimationComplete] =
    useState(false);

  const navigate = useNavigate();

  const createReadingMutation = useMutation({
    mutationFn: createReading,
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["dailyReading"] });
      await queryClient.refetchQueries({ queryKey: ["readings"] });
    }
  });

  useEffect(() => {
    if (tarotCards) {
      const shuffledCards = shuffleArray(tarotCards);
      setCards(shuffledCards);
    }
  }, [tarotCards]);

  const shuffleArray = (array: CardType[]): CardType[] => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const handleDragEnd = async (cardId: number) => {
    setDraggedCards((prevDraggedCards) => {
      if (!prevDraggedCards.includes(cardId)) {
        return [...prevDraggedCards, cardId];
      }
      return prevDraggedCards;
    });
    setIsDoneDragging(true);
  };

  const handleCardReveal = async (cardId: number) => {
    try {
      const result = await createReadingMutation.mutateAsync({
        cardId
      });
      setReadingId(result.readingId);
    } catch (error) {
      console.error("Error revealing card:", error);
      toast({
        title: "Error",
        description: "Failed to create reading. Please try again.",
        variant: "destructive"
      });
    }
  };

  const handleGetInterpretation = () => {
    if (readingId) {
      navigate(`/readings/${readingId}`);
    } else {
      console.error("No reading ID available");
      // TODO: Handle error (e.g., show error message to user)
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.5,
        ease: "easeInOut",
        staggerChildren: 1
      }
    }
  };

  useEffect(() => {
    if (dailyReading) {
      // If a reading exists, set up the state as if that card was drawn
      const drawnCard = dailyReading.card;
      if (drawnCard) {
        setDraggedCards([drawnCard.id]);
        setIsDoneDragging(true);
        setIsRevealed(true);
        setReadingId(dailyReading.id.toString());
      }
    }
  }, [dailyReading]);

  const handleAddCard = () => {
    setIsCardSelectionOpen(true);
  };

  const handleCardSelect = (cardId: number) => {
    handleDragEnd(cardId);
    setIsRevealed(true);
    handleCardReveal(cardId);
  };

  if (
    (isTarotCardsLoading && !isTarotCardsFetching) ||
    (isDailyReadingLoading && !isDailyReadingFetching)
  ) {
    return (
      <Layout>
        <CardLoader loadingText="Loading your daily draw..." />
      </Layout>
    );
  }

  return (
    <Layout disablePadding>
      <div className="relative h-[calc(100vh-48px)] md:h-[calc(100vh-64px-1rem)] overflow-hidden">
        <h1 className="md:block hidden absolute z-40 w-full text-3xl text-center font-bold mb-6 text-orange-950">
          Daily Draw
        </h1>

        <div className="absolute z-30 bottom-0 transform w-full h-full flex justify-center items-center pointer-events-none">
          {/* Centered Card Outline */}
          <div className="absolute inset-0 flex items-center justify-center space-x-10">
            <div
              className={cn(
                "-mt-24 w-[150px] md:w-[300px] aspect-[400/700] flex border-2 border-dashed border-primary-foreground/20 bg-background-dark/10 rounded-lg items-center justify-center",
                {
                  "hover:bg-background-dark cursor-pointer pointer-events-auto":
                    !isRevealed
                }
              )}
              onClick={() => {
                if (!isRevealed) {
                  handleAddCard();
                }
              }}
            >
              <span className="text-orange-950 text-center uppercase font-sans text-xs text-primary-foreground/50">
                Drag a card or
                <br />
                click to a add card
              </span>
            </div>
          </div>

          {/* Button To Get Interpretation */}

          {isRevealed && (
            <AnimatePresence>
              {/* Fade In */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5, delay: 1.5 }}
              >
                <PushableButton
                  className="relative -bottom-32 sm:-bottom-32 pointer-events-auto z-50"
                  text="Go To Reading"
                  size={windowWidth < 640 ? "small" : "normal"}
                  onClick={handleGetInterpretation}
                />
              </motion.div>
            </AnimatePresence>
          )}

          {cards.length > 0 && (
            <AnimatePresence>
              <motion.div
                className="pointer-events-none absolute inset-0 flex items-center justify-center"
                variants={containerVariants}
              >
                {cards.map((card, index) => (
                  <Card
                    key={card.id}
                    card={card}
                    index={index}
                    total={cards.length}
                    draggedCards={draggedCards}
                    onDragEnd={handleDragEnd}
                    isDoneDragging={isDoneDragging}
                    isRevealed={isRevealed}
                    setIsRevealed={setIsRevealed}
                    onReveal={handleCardReveal}
                    hoverState={hoverState}
                    isInitialAnimationComplete={isInitialAnimationComplete}
                    setIsInitialAnimationComplete={
                      setIsInitialAnimationComplete
                    }
                  />
                ))}
              </motion.div>
            </AnimatePresence>
          )}
        </div>
      </div>
      <CardSelectionDialog
        open={isCardSelectionOpen}
        onOpenChange={setIsCardSelectionOpen}
        cards={cards}
        onCardSelect={handleCardSelect}
      />
    </Layout>
  );
};

interface CardProps {
  card: CardType;
  index: number;
  total: number;
  onDragEnd: (cardId: number) => void;
  isDoneDragging: boolean;
  draggedCards: number[];
  isRevealed: boolean;
  setIsRevealed: (isRevealed: boolean) => void;
  onReveal: (cardId: number) => void;
  hoverState: {
    hoveredIndex: number | null;
    setHoveredIndex: (index: number | null) => void;
    randomLift: number;
  };
  isInitialAnimationComplete: boolean;
  setIsInitialAnimationComplete: (isInitialAnimationComplete: boolean) => void;
}

const yPositionDragged = "calc(-50vh)";

const Card: React.FC<CardProps> = ({
  card,
  index,
  total,
  onDragEnd,
  isDoneDragging,
  draggedCards,
  isRevealed,
  setIsRevealed,
  onReveal,
  hoverState,
  isInitialAnimationComplete,
  setIsInitialAnimationComplete
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const isDragged = draggedCards.includes(card.id);
  const cardRef = useRef<HTMLDivElement>(null);

  const { width: windowWidth } = useWindowSize();
  const scale = getResponsiveScale(windowWidth);

  // Calculate card position on the arc
  const { x, y, angle } = useCardPosition(index, total);

  // Define styles and animations
  const cardStyles = useCardStyles(isDoneDragging, isDragged);
  const cardAnimations = useCardAnimations(
    isDragged,
    isRevealed,
    x,
    y,
    angle,
    index,
    hoverState,
    isInitialAnimationComplete,
    setIsInitialAnimationComplete
  );

  // Add parallax effect
  useEffect(() => {
    if (isDragged && isRevealed) {
      const handleMouseMove = (e: MouseEvent) => {
        if (cardRef.current) {
          const rect = cardRef.current.getBoundingClientRect();
          const cardCenterX = rect.left + rect.width / 2;
          const cardCenterY = rect.top + rect.height / 2;

          const mouseX = (e.clientX - cardCenterX) / (rect.width / 2);
          const mouseY = (e.clientY - cardCenterY) / (rect.height / 2);

          const tiltX = -mouseY * 0.5;
          const tiltY = -mouseX * 1.5;

          cardRef.current.style.transform = `
            perspective(1000px)
            rotateX(${tiltX}deg)
            rotateY(${tiltY}deg)
            translateY(${yPositionDragged})
            scale(${scale})
          `;
        }
      };

      window.addEventListener("mousemove", handleMouseMove);

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [isDragged, isRevealed, scale]);

  const handleCardClick = async () => {
    if (isDragged && !isRevealed) {
      setIsRevealed(true);
      await onReveal(card.id);
    }
  };

  return (
    <motion.div
      ref={cardRef}
      className={cn(
        "absolute w-[180px] sm:w-[200px] overflow-hidden aspect-[375/629] top-3/4 rounded-lg lg:rounded-2xl p-3 pb-[46px] bg-[#f4e7d8] border border-black border-opacity-40 pointer-events-auto",
        {
          "cursor-grabbing": isDragging && !isRevealed,
          "cursor-grab": !isDragging && !isRevealed,
          "m-0 z-10": isDragged
        }
      )}
      style={{
        ...cardStyles,
        transformStyle: "preserve-3d",
        transition:
          isDragged && isRevealed ? "transform 0.1s ease-out" : undefined
      }}
      {...cardAnimations}
      drag={(!isDragged && draggedCards.length === 0) || !isDoneDragging}
      dragConstraints={{
        top: -window.innerHeight + 300,
        bottom: 0,
        left: -500,
        right: 500
      }}
      dragElastic={0.6}
      dragTransition={{
        bounceStiffness: 300,
        bounceDamping: 20,
        power: 0.2,
        timeConstant: 200,
        restDelta: 0.5,
        min: -800,
        max: 800
      }}
      whileDrag={{
        scale: 1.02,
        rotate: isDragging ? [-0.5, 0.5] : 0,
        transition: {
          rotate: {
            repeat: Infinity,
            repeatType: "mirror",
            duration: 0.3
          }
        }
      }}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={(_, info) =>
        handleDragEnd(info, isDragged, card.id, onDragEnd, setIsDragging)
      }
      onClick={handleCardClick}
    >
      <div
        className="relative w-full h-full"
        style={{ transformStyle: "preserve-3d" }}
      >
        <CardNumber
          isRevealed={isRevealed}
          cardNumber={card.cardNumber}
          isDragged={isDragged}
        />
        <CardBack isDragged={isDragged} isRevealed={isRevealed} />
        <CardFront card={card} isDragged={isDragged} isRevealed={isRevealed} />
      </div>
    </motion.div>
  );
};

const useCardPosition = (index: number, total: number) => {
  const angle = (index / (total - 1)) * 90 - 45; // -45 to 45 degrees
  const radius = 800;
  const scaleY = 0.5;
  const x = Math.sin((angle * Math.PI) / 180) * radius;
  const topYMargin = 50;
  const y =
    -Math.cos((angle * Math.PI) / 180) * radius * scaleY +
    radius * scaleY +
    topYMargin;
  return { x, y, angle };
};

const useDraggedPosition = () => {
  const { width } = useWindowSize();
  const scale = getResponsiveScale(width);

  return {
    x: 0,
    y: yPositionDragged,
    scale: scale,
    rotate: 0
  };
};

const useCardStyles = (isDoneDragging: boolean, isDragged: boolean) => ({
  transformStyle: "preserve-3d" as const,
  transition: "transform 0.3s ease-out",
  ...(isDoneDragging && isDragged ? { margin: 0 } : {})
});

const useCardAnimations = (
  isDragged: boolean,
  isRevealed: boolean,
  x: number,
  y: number,
  angle: number,
  index: number,
  hoverState: CardProps["hoverState"],
  isInitialAnimationComplete: boolean,
  setIsInitialAnimationComplete: (isInitialAnimationComplete: boolean) => void
) => {
  const draggedPosition = useDraggedPosition();
  const { hoveredIndex, randomLift } = hoverState;

  // Add useEffect to detect when initial animation is complete
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialAnimationComplete(true);
    }, 600 + index * 0.02 * 1000); // Convert to milliseconds

    return () => clearTimeout(timer);
  }, []);

  const getNeighborLift = (distance: number) => {
    const maxNeighborDistance = 2; // How many cards to the left and right to affect
    const baseNeighborLift = randomLift * 0.5; // Base lift amount for neighbors (50% of main lift)

    if (distance > maxNeighborDistance) return 0;

    return baseNeighborLift * (1 - distance / (maxNeighborDistance + 1));
  };

  const calculateLift = () => {
    if (hoveredIndex === null) return 0;

    if (hoveredIndex === index) {
      return randomLift;
    }

    const distance = Math.abs(hoveredIndex - index);
    return getNeighborLift(distance);
  };

  const calculateRotation = () => {
    return angle; // For now, just return the base angle
  };

  const getHoverTransition = () => ({
    duration: isInitialAnimationComplete ? 0.2 : 0.6,
    ease: "easeOut",
    delay: 0
  });

  return {
    initial: isDragged
      ? draggedPosition
      : {
          y: y + 200 + 10 * index,
          rotate: angle,
          rotateY: 180,
          opacity: 1
        },
    animate: isDragged
      ? {
          ...draggedPosition,
          rotateY: isRevealed ? 0 : 180,
          translateZ: isRevealed ? -100 : [2, 180, 2],
          rotateX: 5,
          rotate: 0
        }
      : {
          x,
          y: y - calculateLift(),
          rotateY: 180,
          opacity: 1,
          transition: hoveredIndex !== null ? getHoverTransition() : undefined
        },
    whileHover:
      !isDragged && !isRevealed
        ? {
            x: x - 20,
            y: y - calculateLift(),
            rotate: calculateRotation(),
            transition: getHoverTransition()
          }
        : undefined,
    transition: {
      default: {
        duration: isInitialAnimationComplete ? 0.2 : 0.6,
        delay: isDragged ? 0 : 0.02 * index
      },
      rotate: {
        delay: isDragged
          ? 0
          : isInitialAnimationComplete
          ? 0
          : 1 + 0.02 * index,
        ease: "easeOut"
      }
    },
    onHoverStart: () => {
      if (!isDragged && !isRevealed) {
        hoverState.setHoveredIndex(index);
      }
    },
    onHoverEnd: () => {
      if (!isDragged && !isRevealed) {
        hoverState.setHoveredIndex(null);
      }
    }
  };
};

const handleDragEnd = (
  info: any,
  isDragged: boolean,
  cardId: number,
  onDragEnd: (cardId: number) => void,
  setIsDragging: (isDragging: boolean) => void
) => {
  if (!isDragged && info.point.y < window.innerHeight - 200) {
    onDragEnd(cardId);
    setIsDragging(false);
  }
};

// Subcomponents for CardContent
const CardNumber: React.FC<{
  cardNumber: number;
  isDragged: boolean;
  isRevealed: boolean;
}> = ({ cardNumber, isDragged, isRevealed }) => (
  <motion.div
    animate={{
      opacity: isDragged && isRevealed ? 1 : 0,
      transition: { delay: 0.15 }
    }}
    className="flex items-center absolute w-full justify-center z-50 top-0"
  >
    <span className="absolute flex items-center justify-center font-rosarivo text-xs border border-t-0 border-black/60 bg-[#f4e7d8] z-50 font-left-0 mx-4 w-8 h-4 mt-4 rounded-br-full rounded-bl-full">
      <span className="z-10 text-xs -mt-2">{cardNumber}</span>
    </span>
  </motion.div>
);

const CardBack: React.FC<{ isDragged: boolean; isRevealed: boolean }> = ({
  isDragged,
  isRevealed
}) => (
  <motion.div
    className="absolute w-full h-[calc(100%+50px-1rem)] backface-hidden pointer-events-none border border-black border-opacity-70 rounded-md"
    style={{ backfaceVisibility: "hidden" }}
    animate={{ opacity: isDragged && isRevealed ? 0 : 1 }}
  >
    <img
      src={cardBack}
      className="object-cover w-full h-full pointer-events-none rounded-md"
      alt="Card Back"
    />
  </motion.div>
);

const CardFront: React.FC<{
  card: CardType;
  isDragged: boolean;
  isRevealed: boolean;
}> = ({ card, isDragged, isRevealed }) => {
  // Get mouse position and percentage of the screen
  // Don't use any libraries for this, just plain JS
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const { width } = useWindowSize();
  const scale = getResponsiveScale(width);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      // Calculate the percentage of the screen
      const percentageX = e.clientX / window.innerWidth;
      const percentageY = e.clientY / window.innerHeight;

      setOffset({ x: percentageX, y: percentageY });
    };

    if (isDragged) window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDragged]);

  return (
    <motion.div
      className="absolute w-full h-full backface-hidden pointer-events-none"
      style={{ backfaceVisibility: "hidden" }}
      animate={{ opacity: isDragged && isRevealed ? 1 : 0 }}
      transition={{ duration: 0.1, delay: isDragged ? 0.2 : 0 }}
    >
      <div className="overflow-hidden relative w-full h-full rounded-t-md">
        <motion.img
          src="/rays.jpg"
          style={{
            transform: `translate(${(offset.x - 0.5) * 10}%, ${
              (offset.y - 0.5) * 10
            }%) scale(${scale})`
          }}
          className="absolute inset-0 mix-blend-color-dodge object-cover pointer-events-none opacity-30 rounded-md"
        />
        <img
          src={card.imageUrl || ""}
          className="object-cover rounded-md rounded-b-none w-full h-full pointer-events-none border border-black/40"
          alt={card.name}
        />
      </div>
      <div className="relative inset-x-0 bottom-0 bg-[#f4e7d8] border border-t-0 text-center border-black/40 rounded-b-md p-1">
        <span className="font-rosarivo text-xs">{card.name}</span>
      </div>
    </motion.div>
  );
};

const getResponsiveScale = (windowWidth: number) => {
  // if (windowWidth < 768) return 0.95; // md - reduced from 0.95
  if (windowWidth < 1024) return 0.95; // lg - reduced from 1.1
  if (windowWidth < 1280) return 1.05; // xl - reduced from 1.2
  if (windowWidth < 1536) return 1.1; // 2xl - reduced from 1.25
  if (windowWidth < 1800) return 1.15; // custom breakpoint for 1600px screens
  return 1.2; // larger than 1800px - reduced from 1.3
};

export default DailyDraw;
