import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "@/utils/api";
import { toast } from "@/components/ui/use-toast";
import Layout from "@/components/layout";

export default function LogoutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        logoutUser();
        navigate("/login", {
          state: { message: "You have been successfully logged out." }
        });
      } catch (error) {
        console.error("Error during logout:", error);
        toast({
          title: "Something went wrong.",
          description: "Please try again."
        });
      }
    };

    performLogout();
  }, [navigate]);

  return (
    <Layout>
      <p>Logging out...</p>
    </Layout>
  );
}
