import BlurBackground from "@/components/BlurBackground";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card";
import { User } from "lucide-react";
import { useEffect } from "react";
import { z } from "zod";
import Navbar from "@/components/navbar";
import { useSearchParams } from "react-router-dom";
import { TarotFormField } from "@/components/TarotFormField";
import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PushableButton } from "@/components/PushableButton";
import { guides } from "./Guides";
import { cn } from "@/lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import { registerUser } from "@/utils/api";
import { useUserStore } from "@/stores/useUserStore";
import { useMutation } from "@tanstack/react-query";

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email address"),
  password: z.string().min(8, "Password must be at least 8 characters long"),
  preferredGuideId: z.number().min(1, "Please select a guide")
});

type FormData = z.infer<typeof formSchema>;

export default function RegisterPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { setUser } = useUserStore();

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      preferredGuideId: 1
    }
  });

  useEffect(() => {
    // Pre-fill form fields from URL parameters
    const name = searchParams.get("name");
    const email = searchParams.get("email");

    if (name) form.setValue("name", name);
    if (email) form.setValue("email", email);
  }, [searchParams, form]);

  const mutation = useMutation({
    mutationFn: registerUser,
    onSuccess: (data) => {
      localStorage.setItem("token", data.token);
      setUser(data.user);
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
      navigate("/dashboard", {
        state: {
          message: "Please check your email to verify your account."
        }
      });
    },
    onError: (error) => {
      console.error("Error:", error);
      // You might want to add error handling here
    }
  });

  const onSubmit = async (values: FormData) => {
    mutation.mutate(values);
  };

  const handleGuideSelect = (guideId: number) => {
    form.setValue("preferredGuideId", guideId);
  };

  const selectedGuide = form.watch("preferredGuideId");

  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center min-h-[calc(100vh-76px)] my-10">
        <div className="flex flex-1 justify-start content-start flex-wrap max-w-4xl">
          <Card className="w-full overflow-auto z-10 relative">
            <CardHeader className="bg-primary bg-opacity-50">
              <CardTitle className="font-bold tracking-tight text-primary-foreground sm:text-2xl sm:leading-tight flex items-center">
                <User className="h-6 w-6 text-rose-600 mr-2 relative -top-1" />
                <span>Register</span>
              </CardTitle>
              <CardDescription className="mt-6 text-md leading-8 text-primary-foreground font-rosarivo">
                Pick your guide and create your account to get started
              </CardDescription>
            </CardHeader>
            <CardContent className="bg-background">
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="pt-4 font-rosarivo max-w-2xl mx-auto"
                >
                  <div>
                    <div className="flex flex-col gap-4 items-center w-full mb-8">
                      <h2 className="text-3xl text-center font-semibold mt-8 text-primary-foreground w-full">
                        Choose Your Guide
                      </h2>
                      {guides.map((guide) => (
                        <div
                          className={cn(
                            "flex flex-col gap-3 p-6 border rounded-lg w-full transition-all duration-400 ease-in-out cursor-pointer relative",
                            selectedGuide === guide.id
                              ? "border-rose-600 bg-rose-50/30 shadow"
                              : "border-primary-foreground/20 bg-rose-50/0 hover:border-rose-600/30"
                          )}
                          key={guide.id}
                          onClick={() => handleGuideSelect(guide.id)}
                        >
                          <h3 className="text-2xl font-semibold text-primary-foreground">
                            {guide.name}
                          </h3>
                          {/* {selectedGuide === guide.id && (
                            <span className="text-rose-600 pointer-events-none absolute right-6 font-sans uppercase text-sm">
                              Selected
                            </span>
                          )} */}
                          <p className="italic text-primary-foreground">
                            {guide.description}
                          </p>
                          <ul className="list-disc list-inside space-y-1">
                            {guide.bulletPoints.map((point) => (
                              <li
                                key={point}
                                className="leading-relaxed text-primary-foreground"
                              >
                                {point}
                              </li>
                            ))}
                          </ul>
                          <guide.icon className="w-40 h-40 rotate-[20deg] text-rose-600 opacity-5 absolute right-10 top-14" />
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="border-primary-foreground/20 my-10" />
                  <div className="space-y-6">
                    <TarotFormField
                      name="name"
                      label="First Name"
                      control={form.control}
                      placeholder="First Name"
                    />
                    {form.formState.errors.name && (
                      <p className="text-red-500 text-sm mt-1">
                        {form.formState.errors.name.message}
                      </p>
                    )}
                    <TarotFormField
                      name="email"
                      label="Email"
                      control={form.control}
                      placeholder="your@email.com"
                      type="email"
                    />
                    {form.formState.errors.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {form.formState.errors.email.message}
                      </p>
                    )}

                    <TarotFormField
                      name="password"
                      label="Password"
                      control={form.control}
                      placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                      type="password"
                    />
                    {form.formState.errors.password && (
                      <p className="text-red-500 text-sm mt-1">
                        {form.formState.errors.password.message}
                      </p>
                    )}
                  </div>

                  <div className="mt-10 mb-8 flex justify-between items-center">
                    <PushableButton
                      text={mutation.isPending ? "Registering..." : "Register"}
                      isLoading={mutation.isPending}
                      type="submit"
                      disabled={mutation.isPending}
                    />
                  </div>
                </form>
              </Form>
            </CardContent>
          </Card>
        </div>
        <div className="fixed left-0 w-screen h-screen scale-125 top-1/4 pointer-events-none">
          <BlurBackground />
        </div>
      </div>
    </>
  );
}
