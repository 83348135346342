export default function Banner() {
  return (
    <div className="border-t border-primary-foreground-muted/20 mt-4">
      <div className="py-1 mt-4 bg-rose-500 text-primary-foreground-muted rounded-md border border-rose-500/20">
        <p className="text-xs font-sans uppercase text-white text-center">
          Verify your email <br />
          for 5 free credits
        </p>
      </div>
    </div>
  );
}
