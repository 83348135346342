import { useQuery } from "@tanstack/react-query";
import { useParams, Link } from "react-router-dom";
import Layout from "@/components/layout";
import { getReadingsByCard, getCard, getCardInterpretation } from "@/utils/api";
import { format } from "date-fns";
import { Card as CardType } from "@/types/Card";
import { Badge } from "@/components/ui/badge";
import { useEffect } from "react";
import { useBreadcrumbStore } from "@/stores/breadcrumb";

const CardDetail = () => {
  const { id } = useParams<{ id: string }>();
  const cardId = id ? parseInt(id, 10) : null;
  const { setDynamicSegment } = useBreadcrumbStore();

  const {
    data: cardData,
    isLoading: cardLoading,
    error: cardError
  } = useQuery({
    queryKey: ["card", cardId],
    queryFn: () => {
      if (cardId === null) throw new Error("Invalid card ID");
      return getCard(cardId);
    },
    enabled: cardId !== null && !isNaN(cardId)
  });

  const {
    data: interpretation,
    isLoading: interpretationLoading,
    error: interpretationError
  } = useQuery({
    queryKey: ["cardInterpretation", cardId],
    queryFn: () => {
      if (cardId === null) throw new Error("Invalid card ID");
      return getCardInterpretation(cardId);
    },
    enabled: cardId !== null && !isNaN(cardId)
  });

  const {
    data: readings,
    isLoading: readingsLoading,
    error: readingsError
  } = useQuery({
    queryKey: ["cardReadings", cardId],
    queryFn: () => {
      if (cardId === null) throw new Error("Invalid card ID");
      return getReadingsByCard(cardId);
    },
    enabled: cardId !== null && !isNaN(cardId)
  });

  useEffect(() => {
    if (cardData) {
      setDynamicSegment(`/deck/${id}`, cardData.name);
    }

    return () => {
      if (id) {
        setDynamicSegment(`/deck/${id}`, "");
      }
    };
  }, [id, cardData, setDynamicSegment]);

  if (cardId === null || isNaN(cardId)) {
    return (
      <Layout>
        <div className="h-screen flex items-center justify-center">
          <div className="text-center">
            <p className="text-primary-foreground">Invalid card ID</p>
          </div>
        </div>
      </Layout>
    );
  }

  const isLoading = readingsLoading || cardLoading || interpretationLoading;
  const error = readingsError || cardError || interpretationError;

  if (isLoading) {
    return (
      <Layout>
        <div className="h-screen flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-orange-950 mx-auto mb-4"></div>
            <p className="text-primary-foreground">Loading card details...</p>
          </div>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="h-screen flex items-center justify-center">
          <div className="text-center">
            <p className="text-primary-foreground">
              Error loading card details
            </p>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        {/* Card Detail Section */}
        <div className="mb-8">
          <div className="grid md:grid-cols-3 gap-8">
            {/* Card Image */}
            <div className="md:col-span-1">
              <Card card={cardData} />
            </div>
            {/* Card Information */}
            <div className="md:col-span-2 space-y-6">
              <div>
                <h1 className="text-4xl font-bold text-primary-foreground mb-2 flex items-center">
                  <span>{cardData?.name}</span>
                  <span className="text-base text-primary-foreground ml-4 bg-primary-darker rounded-full px-4 py-1 -mt-1">
                    {cardData?.arcanaType}
                    {cardData?.suit && ` - ${cardData.suit}`}
                  </span>
                </h1>
              </div>

              <div>
                <h3 className="text-xl font-semibold text-primary-foreground mb-3">
                  Keywords
                </h3>
                <div className="flex flex-wrap gap-2">
                  {cardData?.keywords.map((keyword: string) => (
                    <Badge
                      key={keyword}
                      className="bg-background border border-black/20 text-sm"
                    >
                      {keyword}
                    </Badge>
                  ))}
                </div>
              </div>

              {interpretation && (
                <div>
                  <h3 className="text-xl font-semibold text-primary-foreground mb-3">
                    Interpretation
                  </h3>
                  <p className="text-primary-foreground">
                    {interpretation.message}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Readings Section */}
        <div className="mt-16">
          <h2 className="text-3xl font-bold text-primary-foreground mb-6 text-center">
            Past Readings
          </h2>
          <hr className="border-orange-950/10 mb-8" />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {readings && readings.length > 0 ? (
              readings.map((reading) => (
                <Link
                  key={reading.id}
                  to={`/readings/${reading.id}`}
                  className="block group"
                >
                  <div className="bg-background rounded-xl shadow-md overflow-hidden transition-transform hover:scale-105 duration-200">
                    <div className="p-4">
                      <p className="font-medium text-primary-foreground">
                        {format(new Date(reading.createdAt), "MMMM d, yyyy")}
                      </p>
                      <p className="text-primary-foreground/80 mt-1">
                        Daily Draw
                      </p>
                      {reading.context && (
                        <p className="mt-2 text-sm text-primary-foreground/70 line-clamp-2">
                          {reading.context}
                        </p>
                      )}
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="col-span-full text-center py-8">
                <p className="text-primary-foreground">
                  No readings found with this card yet.
                </p>
                <p className="text-primary-foreground mt-2">
                  Draw this card in a reading to see it appear here.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

interface CardProps {
  card: CardType | undefined;
}

const Card: React.FC<CardProps> = ({ card }) => {
  if (!card?.imageUrl) return null;

  return (
    <div className="w-[250px] aspect-[400/700] rounded-2xl p-4 pb-[calc(36px+1rem)] bg-[#f4e7d8] border border-black/40 relative shadow-lg mx-auto">
      <div className="relative w-full h-full">
        <div className="flex items-center absolute w-full justify-center z-50 top-0">
          <span className="absolute flex items-center justify-center font-rosarivo text-xs border border-t-0 border-black/60 bg-[#f4e7d8] z-50 font-left-0 mx-4 w-8 h-4 mt-4 rounded-br-full rounded-bl-full">
            <span className="z-10 text-xs -mt-2">{card.cardNumber}</span>
          </span>
        </div>
        <div className="overflow-hidden relative w-full h-full rounded-t-md">
          <img
            src={card.imageUrl}
            className="object-cover rounded-md rounded-b-none w-full h-full pointer-events-none border border-black/40"
            alt={card.name}
          />
        </div>
        <div className="relative inset-x-0 bottom-0 bg-[#f4e7d8] border border-t-0 text-center border-black/40 rounded-b-md p-1">
          <span className="font-rosarivo text-sm">{card.name}</span>
        </div>
        <div className="absolute z-10 inset-0 bg-[url('/texture.jpg')] bg-cover mix-blend-multiply pointer-events-none"></div>
      </div>
    </div>
  );
};

export default CardDetail;
