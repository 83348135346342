import { Button } from "@/components/ui/button";
import { ChevronDown, LogOut } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { PushableButton } from "./PushableButton";
import Container from "@/components/Container";
import Logo from "./Logo";
import { logoutUser } from "@/utils/api";
import { useUserStore } from "@/stores/useUserStore";

export default function Navbar() {
  const { user } = useUserStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    logoutUser();
    navigate("/");
  };

  return (
    <header className="font-rosarivo">
      <Container>
        <nav
          aria-label="Global"
          className="flex py-4 items-center justify-between "
        >
          <Logo />
          <div className="flex lg:flex-1 lg:justify-end space-x-2 md:space-x-8">
            {user ? (
              <>
                <PushableButton
                  text="Dashboard"
                  onClick={() => navigate("/dashboard")}
                  size="small"
                />
                <div className="flex items-center space-x-2">
                  <div className="w-11 h-11 rounded-full bg-background-dark border-2 border-rose-700 flex items-center justify-center bg-gradient-to-br from-rose-500 to-red-600 text-white text-2xl">
                    <span className="relative mt-0.5 ">A</span>
                  </div>
                  <ChevronDown className="text-red-500" />
                </div>
              </>
            ) : (
              <>
                <Button
                  onClick={() => navigate("/login")}
                  variant={"ghost"}
                  className="text-base"
                >
                  Login
                </Button>
                <PushableButton
                  text="Register"
                  onClick={() => {
                    navigate("/register");
                  }}
                  size="small"
                />
              </>
            )}
          </div>
        </nav>
      </Container>
    </header>
  );
}
