import { cn } from "@/lib/utils";
import { motion } from "framer-motion";

const TornDivider = ({ className }: { className?: string }) => (
  <motion.div
    className={cn(
      "w-[100vw] left-0 absolute h-[100px] bg-gradient-to-b from-primary-foreground/40 to-transparent opacity-20",
      className
    )}
  />
);

// const TornDivider = ({ className }: { className?: string }) => (
//   <motion.div
//     className={cn("w-[100vw] left-0 absolute h-[150px]", className)}
//     style={{
//       WebkitMaskImage: 'url("/paper-shadow-2.svg")',
//       maskImage: 'url("/paper-shadow-2.svg")',
//       WebkitMaskSize: "auto 150px",
//       maskSize: "auto 150px",
//       WebkitMaskRepeat: "repeat-x",
//       maskRepeat: "repeat-x",
//       WebkitMaskPosition: "center",
//       maskPosition: "center"
//     }}
//   >
//     {/* <div className="w-full absolute inset-0 bg-[auto 75px] bg-[url('/paper-mask.svg')] bg-repeat-x h-[175px] bg-[bottom center] top-0" /> */}
//     <div
//       className="w-full bottom-0 absolute h-[150px] bg-contain -top-2 opacity-30 blur"
//       style={{
//         backgroundImage: 'url("/paper-shadow.svg")',
//         backgroundRepeat: "repeat-x",
//         backgroundPosition: "center",
//         backgroundSize: "auto 150px"
//       }}
//     />
//   </motion.div>
// );

export default TornDivider;
