import React from "react";
import { motion } from "framer-motion";
import { Loader } from "lucide-react";
import { Textures } from "./Textures";

interface PushableButtonProps {
  text: string;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  size?: "small" | "normal";
  type?: "button" | "submit" | "reset";
  className?: string;
  disabled?: boolean;
  href?: string;
  isLoading?: boolean;
}

export const PushableButton: React.FC<PushableButtonProps> = ({
  text,
  onClick,
  size = "normal",
  type = "button",
  className = "",
  disabled = false,
  href,
  isLoading = false
}) => {
  const baseClasses =
    "relative border-none bg-transparent p-0 cursor-pointer outline-offset-4 select-none touch-manipulation";
  const shadowClasses = "absolute top-0 left-0 w-full h-full bg-black/25";
  const edgeClasses =
    "absolute top-0 left-0 w-full h-full bg-gradient-to-l from-rose-900 via-rose-800 to-rose-900";
  const frontClasses =
    "flex items-center justify-center block relative text-white bg-rose-600 font-semibold cursor-pointer";

  const sizeClasses = {
    normal: {
      button: "",
      shadow: "rounded-xl",
      edge: "rounded-xl",
      front: "py-3 px-7 md:px-10 rounded-xl text-lg md:text-xl"
    },
    small: {
      button: "scale-75 md:scale-90",
      shadow: "rounded-lg",
      edge: "rounded-lg",
      front: "py-2 px-5 md:px-7 rounded-lg text-base md:text-lg"
    }
  };

  const ButtonContent = () => (
    <>
      <motion.span
        className={`${shadowClasses} ${sizeClasses[size].shadow}`}
        initial={{ y: 2 }}
        whileHover={{ y: 4 }}
        whileTap={{ y: 1 }}
        transition={{ type: "spring", stiffness: 300, damping: 20 }}
      />
      <span className={`${edgeClasses} ${sizeClasses[size].edge}`} />
      <motion.span
        className={`${frontClasses} ${sizeClasses[size].front}`}
        initial={{ y: -4 }}
        whileHover={{ y: -6 }}
        whileTap={{ y: -2 }}
        transition={{ type: "spring", stiffness: 300, damping: 20 }}
      >
        <span>{text}</span>
        {isLoading && (
          <span className="ml-2">
            <Loader className="w-5 h-5 animate-spin" />
          </span>
        )}
      </motion.span>
    </>
  );

  if (href) {
    return (
      <motion.a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={`inline-block ${baseClasses} ${sizeClasses[size].button} ${className}`}
        onClick={onClick}
        whileHover={{ filter: "brightness(110%)" }}
      >
        <ButtonContent />
      </motion.a>
    );
  }

  return (
    <motion.button
      className={`${baseClasses} ${sizeClasses[size].button} ${className}`}
      role="button"
      onClick={onClick}
      type={type}
      whileHover={{ filter: "brightness(110%)" }}
      disabled={disabled}
    >
      <ButtonContent />
    </motion.button>
  );
};
