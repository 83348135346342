import React, { useState, useEffect, useRef } from "react";
import {
  motion,
  AnimatePresence,
  useScroll,
  useMotionValueEvent
} from "framer-motion";
import Card from "./CardComponents";
import { Card as CardType } from "@/types/Card";
import { cn } from "@/lib/utils";
import { PushableButton } from "./PushableButton";
import { useInView } from "framer-motion";
import { Textures } from "./Textures";
import TornDivider from "./TornDivider";

const TypingIndicator = ({ className }: { className?: string }) => (
  <motion.div
    className={cn("flex items-center space-x-2 absolute z-50", className)}
    initial={{ opacity: 0 }}
    animate={{ opacity: 0.75 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    <motion.div
      className="w-1 h-1 bg-primary-foreground rounded-full"
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ duration: 0.5, repeat: Infinity }}
    />
    <motion.div
      className="w-1 h-1 bg-primary-foreground rounded-full"
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ duration: 0.5, repeat: Infinity, delay: 0.1 }}
    />
    <motion.div
      className="w-1 h-1 bg-primary-foreground rounded-full"
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ duration: 0.5, repeat: Infinity, delay: 0.2 }}
    />
  </motion.div>
);

export const ReadingDemo = () => {
  const [isRevealed, setIsRevealed] = useState(true);
  const ref = useRef(null);
  const chatRef = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  const isChatInView = useInView(chatRef, { once: true, margin: "-100px" });
  const { scrollY } = useScroll();
  const [yOffset, setYOffset] = useState(0);

  // Track scroll position and update yOffset
  useMotionValueEvent(scrollY, "change", (latest) => {
    setYOffset(latest * 0.02); // Adjust multiplier for intensity
  });

  const demoCard: CardType = {
    id: 0,
    name: "The Fool",
    cardNumber: 0,
    arcanaType: "Major Arcana",
    suit: null,
    keywords: ["new beginnings", "beginner", "innocent", "carefree"],
    imageUrl:
      "https://tarot-kjkapeerbjmrgiku.nyc3.digitaloceanspaces.com/default/00-fool.jpg"
  };

  return (
    <div ref={ref} id="reading-demo" className="relative">
      <motion.div
        className="mx-auto max-w-3xl text-center pt-32 relative"
        initial={{ opacity: 0, y: 20 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <div className="w-full flex justify-center mt-12">
          {/* Rotating moon in a loop */}
          <motion.img
            src="/card-assets/star-1.png"
            alt="Wheel of Fortune"
            initial={{ opacity: 0, y: -10 }}
            animate={
              isInView
                ? {
                    opacity: 1,
                    rotate: [0, 360, 0]
                  }
                : { opacity: 0, y: -10 }
            }
            transition={{
              opacity: { duration: 1, delay: 1 },
              rotate: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 60,
                ease: "easeInOut"
              }
            }}
            className="w-24 h-24 object-contain -top-6 relative origin-center"
          />
        </div>
        <h2 className="text-3xl text font-bold tracking-tight leading-loose text-primary-foreground sm:text-4xl sm:leading-[3.5rem]">
          Unlock The Ancient Wisdom <br />
          Of Tarot With Your Guide
        </h2>
        <p className="mt-2 text-lg leading-8 text-primary-foreground font-rosarivo mx-auto">
          Learn tarot naturally as you explore. Your personal AI guide turns
          ancient wisdom into clear insights, unlocking tarot's wisdom card by
          card.
        </p>
      </motion.div>
      <TornDivider className="bottom-[80px]" />
      <motion.div
        className="mx-auto max-w-7xl grid grid-cols-2 mt-32 relative"
        initial={{ opacity: 0, y: 40 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <div className="flex flex-col justify-center items-center col-span-1 relative">
          <div className="relative z-10">
            <Card
              card={demoCard}
              isRevealed={isRevealed}
              setIsRevealed={setIsRevealed}
              inverseHoverEffect
            />
          </div>
          <div className="absolute inset-0 flex items-center justify-center -z-0">
            <div className="h-[70%] w-48 bg-primary-foreground/50 rounded-full inline-block blur-3xl" />
          </div>
        </div>
        <AnimatePresence>
          <motion.div
            ref={chatRef}
            initial={{ opacity: 0, y: 100 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 1, delay: 0.6 }}
          >
            <motion.div
              style={{
                transformStyle: "preserve-3d",
                perspective: "1000px",
                y: 0.5 // Apply positive offset for downward movement
              }}
              animate={{
                transform: "perspective(1000px) rotate3d(1, -1, 0, 4deg)"
              }}
              transition={{
                duration: 2,
                ease: "easeInOut"
              }}
            >
              <h3 className="text-2xl font-bold text-primary-foreground text-center space-x-4 mb-4">
                <motion.img
                  src="/card-assets/sign-l.png"
                  alt="Sign"
                  className="w-6 h-6 object-contain inline-block"
                  initial={{}}
                  animate={{
                    rotate: [0, 10, 0, -10, 0]
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "easeInOut"
                  }}
                />
                <span>Example Reading</span>
                <motion.img
                  src="/card-assets/sign-r.png"
                  alt="Sign"
                  className="w-6 h-6 object-contain inline-block"
                  initial={{}}
                  animate={{
                    rotate: [0, -10, 0, 10, 0]
                  }}
                  transition={{
                    duration: 3,
                    delay: 0.2,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "easeInOut"
                  }}
                />
              </h3>

              <motion.div className="col-span-1 rounded-2xl p-4 relative bg-background overflow-hidden shadow-xl border border-primary-foreground/20 pointer-events-none">
                <div className="col-span-1 rounded-2xl bg-background-dark border-foreground/10 border relative overflow-hidden shadow">
                  <div className="relative z-10 w-full">
                    <ChatMessages inView={isChatInView} />
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

const ChatMessages: React.FC<{ inView: boolean }> = ({ inView }) => {
  const [displayedMessages, setDisplayedMessages] = useState<
    Array<{
      type: "user" | "guide";
      content: string;
    }>
  >([]);
  const [isTyping, setIsTyping] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [isDone, setIsDone] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const conversationStartedRef = useRef(false);

  const simulateConversation = async (signal: AbortSignal) => {
    if (conversationStartedRef.current) return;
    conversationStartedRef.current = true;

    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    try {
      // Initial guide message
      setIsTyping(true);
      await delay(2000);
      if (signal.aborted) return;

      setDisplayedMessages([
        {
          type: "guide",
          content: readingData.conversation.initialInterpretations[0].message
        }
      ]);
      setIsTyping(false);
      await delay(1500);
      if (signal.aborted) return;

      // First user question
      const question1 = readingData.conversation.followUps[0].question;
      for (let i = 0; i <= question1.length; i++) {
        if (signal.aborted) return;
        setUserInput(question1.slice(0, i));
        await delay(35);
      }
      await delay(800);
      if (signal.aborted) return;

      setUserInput("");
      setDisplayedMessages((prev) => [
        ...prev,
        { type: "user", content: question1 }
      ]);
      console.log("user input added");
      setIsTyping(true);
      await delay(1000);
      if (signal.aborted) return;

      // First guide response
      setIsTyping(true);
      await delay(2500);
      if (signal.aborted) return;

      setDisplayedMessages((prev) => [
        ...prev,
        {
          type: "guide",
          content: readingData.conversation.followUps[0].responses[0].message
        }
      ]);
      setIsTyping(false);
      await delay(2000);
      if (signal.aborted) return;

      // Second part of the first response
      setIsTyping(true);
      await delay(2000);
      if (signal.aborted) return;

      setDisplayedMessages((prev) => [
        ...prev,
        {
          type: "guide",
          content: readingData.conversation.followUps[0].responses[1].message
        }
      ]);

      // Third part of the first response
      setIsTyping(true);
      await delay(2000);
      if (signal.aborted) return;

      setDisplayedMessages((prev) => [
        ...prev,
        {
          type: "guide",
          content: readingData.conversation.followUps[0].responses[2].message
        }
      ]);

      setIsTyping(false);
      setIsDone(true);
    } catch (error) {
      console.error("Error in conversation simulation:", error);
    } finally {
      setIsTyping(false);
    }
  };

  useEffect(() => {
    if (!inView) return; // Only start the conversation when in view

    conversationStartedRef.current = false;
    const controller = new AbortController();
    abortControllerRef.current = controller;

    const timeoutId = setTimeout(() => {
      if (!conversationStartedRef.current) {
        simulateConversation(controller.signal);
      }
    }, 1000); // Added a small delay after coming into view

    return () => {
      clearTimeout(timeoutId);
      controller.abort();
      abortControllerRef.current = null;
    };
  }, [inView]); // Added inView as a dependency

  return (
    <div className="flex flex-col w-full relative z-50">
      <AnimatePresence mode="sync">
        <motion.div
          className={cn(
            "p-2 space-y-10 h-[300px] shadow-inner relative w-full flex-shrink-0",
            {
              "overflow-hidden": !isDone,
              "overflow-y-auto": isDone
            }
          )}
          transition={{
            layout: { duration: 0.3, ease: "easeOut" }
          }}
        >
          <motion.div className="rounded-b-md rounded-t-xl shadow-inner h-full bg-background border border-primary-foreground/20 overflow-hidden p-2 flex flex-col justify-end pb-4 relative">
            <div className="absolute left-0 h-full p-4 z-10 bottom-0 w-full  bg-transparent border-x-2 border-y-4 border-black blur opacity-20" />
            {displayedMessages.map((message, index) => (
              <Message
                key={index}
                type={message.type}
                content={message.content}
              />
            ))}
            <AnimatePresence>
              {isTyping && <TypingIndicator className="left-4" />}
            </AnimatePresence>
          </motion.div>
        </motion.div>
      </AnimatePresence>
      <div
        className={cn(
          "flex flex-col pt-2 px-2 z-10 shadow-inner pb-2 bg-background border-t border-primary-foreground/20 transition-opacity duration-200 ease-in-out",
          {
            // "opacity-50": isDone
          }
        )}
      >
        <textarea
          value={userInput}
          readOnly
          // rows={3}
          className="flex-1 bg-background shadow-inner border-foreground/10 w-full mb-1 border text-sm rounded-lg px-4 py-4 focus:outline-none focus:ring-1 focus:ring-primary resize-none text-primary-foreground relative z-20"
          placeholder="Type your question..."
          style={{ minHeight: "90px" }}
        />
        <PushableButton
          className={cn(
            "w-full z-50 cursor-events-auto hover:opacity-50 transition-opacity duration-200 ease-in-out relative",
            {
              "cursor-not-allowed": !isDone
            }
          )}
          onClick={() => {
            // if (!isDone) return;
            document.querySelector("#pick-your-guide")?.scrollIntoView({
              behavior: "smooth"
            });
          }}
          text={"Send"}

          // disabled
        />
      </div>
    </div>
  );
};

const Message: React.FC<{
  type: "user" | "guide";
  content: string;
}> = ({ type, content }) => (
  <motion.div
    initial={{ opacity: 0, y: 10, scale: 0.9 }}
    animate={{ opacity: 1, y: 0, scale: 1 }}
    exit={{ opacity: 0, y: -10, scale: 0.9 }}
    transition={{
      layout: { duration: 0.2, delay: 0.2 },
      opacity: { duration: 0.2, delay: 0.4 },
      scale: { duration: 0.2, delay: 0.4 },
      y: { duration: 0.2, delay: 0.4 }
    }}
    className={cn("mb-4 mt-6 flex", {
      "justify-end": type === "user",
      "justify-start": type === "guide"
    })}
  >
    <div
      className={cn(
        "px-6 py-4 border border-primary-foreground/10 rounded-2xl w-3/4 relative",
        {
          "bg-primary-darker rounded-br-sm": type === "user",
          "bg-primary-dark rounded-bl-sm": type === "guide"
        }
      )}
    >
      <span
        className={cn("text-xs uppercase absolute -top-5 text-orange-950/40", {
          "right-6": type === "user",
          "left-6": type === "guide"
        })}
      >
        {type === "user" ? "You" : "GUIDE"}
      </span>
      <p
        className={cn("whitespace-pre-line text-sm", {
          "text-orange-950": type === "user",
          "text-yellow-950": type === "guide"
        })}
      >
        {content}
      </p>
    </div>
  </motion.div>
);

const readingData = {
  conversation: {
    initialInterpretations: [
      {
        message:
          "The Fool brings such wonderful energy of new beginnings! This card appears when life presents us with fresh opportunities and exciting possibilities. Like the figure in the card, you're being invited to step forward with an open heart, carrying only what truly matters. There's such beautiful wisdom in this willingness to begin anew, to trust in the journey ahead."
      }
    ],
    followUps: [
      {
        question:
          "Could you tell me more about the symbolism in the card, and also... I'm wondering if this relates to my desire to change careers?",
        responses: [
          {
            message:
              "Let me share the symbolism! The cliff shows that magical space between known and unknown. The white dog represents your inner wisdom, while the small bag reminds us to travel light. And those mountains? They're the experiences that brought you here, ready for this moment."
          },
          {
            message:
              "The Fool carries profound wisdom for your career change! This archetype teaches us that true courage isn't about knowing every step - it's about trusting your ability to learn and grow."
          },
          {
            message:
              "What's your heart telling you about this new direction? But,  before you answer that, let's first pick your guide!"
          }
        ]
      }
    ]
  }
};
