import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "@/pages/landing";
import Login from "@/pages/login";
import Register from "./pages/register";
import DailyDraw from "./pages/DailyDraw";
import About from "./pages/about";
import Policy from "./pages/policy";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import ProtectedRoute from "./components/ProtectedRoute";
import Deck from "./pages/Deck";
import ReadingDetail from "./pages/ReadingDetail";
import ReadingOverview from "./pages/ReadingOverview";
import Credits from "./pages/Credits";
import { Toaster } from "./components/ui/toaster";
import { useAuth } from "@/hooks/useAuth";
import CardDetail from "./pages/CardDetail";
import Dashboard from "./pages/Dashboard";
import Contact from "./pages/Contact";
import Guides from "./pages/Guides";
import Logout from "./pages/logout";
import Verify from "./pages/verify";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 0, // Consider data stale immediately
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true, // Enable background refetches
      retry: 1
    }
  }
});

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
  key: "REACT_QUERY_OFFLINE_CACHE",
  throttleTime: 1000,
  serialize: (data) => JSON.stringify(data),
  deserialize: (str) => JSON.parse(str)
});

function App() {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        maxAge: 1000 * 60 * 60 * 24 // 24 hours
      }}
    >
      <AppContent />
    </PersistQueryClientProvider>
  );
}

// Create a wrapper component that uses useAuth
function AppContent() {
  useAuth();

  return (
    <div className="bg-background font-rosarivo">
      <Toaster />
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/privacy-policy"
              element={<Policy policy="privacy" />}
            />
            <Route path="/refund-policy" element={<Policy policy="refund" />} />
            <Route path="/terms-of-service" element={<Policy policy="tos" />} />

            {/* Logged In Routes */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/daily"
              element={
                <ProtectedRoute>
                  <DailyDraw />
                </ProtectedRoute>
              }
            />
            <Route
              path="/guides"
              element={
                <ProtectedRoute>
                  <Guides />
                </ProtectedRoute>
              }
            />
            <Route
              path="/deck"
              element={
                <ProtectedRoute>
                  <Deck />
                </ProtectedRoute>
              }
            />
            <Route
              path="/readings/:id"
              element={
                <ProtectedRoute>
                  <ReadingDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/readings"
              element={
                <ProtectedRoute>
                  <ReadingOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path="/feedback"
              element={
                <ProtectedRoute>
                  <Contact />
                </ProtectedRoute>
              }
            />
            <Route
              path="/credits"
              element={
                <ProtectedRoute>
                  <Credits />
                </ProtectedRoute>
              }
            />
            <Route
              path="/deck/:id"
              element={
                <ProtectedRoute>
                  <CardDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/logout"
              element={
                <ProtectedRoute>
                  <Logout />
                </ProtectedRoute>
              }
            />
            <Route path="/verify/:token" element={<Verify />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
