import * as React from "react";
import {
  Calendar,
  Grip,
  HeartHandshake,
  LogOut,
  MessageCircleHeart,
  PieChart,
  Send,
  Stars
} from "lucide-react";
import { NavMain } from "@/components/nav-main";
import { NavSecondary } from "@/components/nav-secondary";
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem
} from "@/components/ui/sidebar";
import { cn } from "@/lib/utils";
import { useUserStore } from "@/stores/useUserStore";
import { useLocation } from "react-router-dom";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const user = useUserStore((state) => state.user);
  const location = useLocation();

  // if (!user) return null; // Don't render sidebar if user is not authenticated

  const currentPath = location.pathname;

  const data = {
    user: {
      name: user?.name,
      email: user?.email,
      avatar: "/avatars/default.jpg" // You might want to add avatarUrl to your User type if needed
    },
    navMain: [
      {
        title: "Dashboard",
        url: "/dashboard",
        icon: PieChart,
        isActive: currentPath === "/dashboard"
      },
      {
        title: "Daily Draw",
        url: "/daily",
        icon: Calendar,
        isActive: currentPath === "/daily"
      },
      // {
      //   title: "Spreads",
      //   url: "/spreads",
      //   icon: LayoutGrid
      // },
      {
        title: "Readings",
        url: "/readings",
        icon: MessageCircleHeart,
        isActive: currentPath.includes("/readings")
      },
      {
        title: "Deck",
        url: "/deck",
        icon: Grip,
        isActive: currentPath === "/deck"
      },
      {
        title: "Guide",
        url: "/guides",
        icon: HeartHandshake,
        isActive: currentPath === "/guides"
      }
      // {
      //   title: "Learn",
      //   url: "/learn",
      //   icon: BookOpen,
      //   items: [
      //     {
      //       title: "Tarot Basics",
      //       url: "/learn/basics"
      //     },
      //     {
      //       title: "Card Meanings",
      //       url: "/learn/meanings"
      //     },
      //     {
      //       title: "Spreads",
      //       url: "/learn/spreads"
      //     }
      //   ]
      // }
    ],
    navSecondary: [
      {
        title: "Credits",
        url: "/credits",
        icon: Stars,
        isActive: currentPath === "/credits"
      },
      {
        title: "Feedback",
        url: "/feedback",
        icon: Send,
        isActive: currentPath === "/feedback"
      },
      {
        title: "Logout",
        url: "/logout",
        icon: LogOut,
        isActive: false
      }
    ]
  };

  return (
    <Sidebar variant="inset" {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <a href="/dashboard">
                <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-primary-darker text-sidebar-primary-foreground">
                  <img
                    className="object-contain p-1"
                    src="/logo.png"
                    width={40}
                    height={40}
                    alt="Logo"
                  />
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span
                    className={cn(
                      "font-bold relative text-orange-950 text-md ml-1"
                    )}
                  >
                    Your Unique Tarot
                  </span>
                </div>
              </a>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={data.navMain} />
        <NavSecondary items={data.navSecondary} className="mt-auto" />
      </SidebarContent>
      {/* <SidebarFooter>
        <NavUser user={data.user} />
      </SidebarFooter> */}
    </Sidebar>
  );
}
