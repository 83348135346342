import Hero from "@/components/hero";
// import Features from "@/components/features";
// import Review from "@/components/review";
// import Pricing from "@/components/pricing";
import Footer from "@/components/footer";
// import FAQ from "@/components/faq";
// import DailyDraw from "@/components/dailyDraw";
// import LandingCardDraw from "@/components/LandingCardDraw";
import {
  motion,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
  useInView
} from "framer-motion";
import Navbar from "@/components/navbar";
import DailyDrawHero from "@/components/DailyDrawHero";
import { ReadingDemo } from "@/components/ReadingDemo";
import { useEffect, useRef, useState } from "react";
import { Textures } from "@/components/Textures";
import { PlayIcon, PauseIcon } from "lucide-react";
import { guides } from "./Guides";
import PricingSection from "@/components/PricingSection";
import TornDivider from "@/components/TornDivider";
import FAQSection from "@/components/FAQSection";
import FeaturesSection from "@/components/FeaturesSection";

interface Star {
  id: number;
  x: number;
  y: number;
  size: number;
  delay: number;
  layer: 1 | 2;
  rotationOffset: number;
}

interface Guide {
  id: number;
  name: string;
  image: string;
  description: string;
  bulletPoints: string[];
  audio: string;
  slogan: string;
}

const generateStars = (count: number): Star[] => {
  const stars: Star[] = [];
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const safeZoneStart = (screenWidth - 1280) / 2;
  const safeZoneEnd = screenWidth - safeZoneStart;

  for (let i = 0; i < count; i++) {
    let x: number;
    if (Math.random() > 0.5) {
      x = Math.random() * safeZoneStart;
    } else {
      x = safeZoneEnd + Math.random() * safeZoneStart;
    }

    stars.push({
      id: i,
      x,
      y: Math.random() * screenHeight,
      size: Math.random() * 25 + 15,
      delay: Math.random(),
      layer: Math.random() > 0.5 ? 1 : 2,
      rotationOffset: Math.random() * 360
    });
  }
  return stars;
};

const AnimatedStars = () => {
  const [stars, setStars] = useState<Star[]>([]);
  const { scrollY } = useScroll();
  const [scrollPosition, setScrollPosition] = useState(0);

  useMotionValueEvent(scrollY, "change", (latest) => {
    setScrollPosition(latest);
  });

  useEffect(() => {
    // Generate initial stars
    setStars(generateStars(15));

    const handleResize = () => {
      setStars(generateStars(15));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="fixed inset-0 z-10 pointer-events-none overflow-hidden"
      style={{ zIndex: 1 }}
    >
      {stars.map((star) => (
        <motion.img
          key={star.id}
          initial={{ opacity: 0, rotate: star.rotationOffset }}
          animate={{
            opacity: [0, 0.6, 0.8, 0],
            y: [star.y, star.y + 15, star.y - 15, star.y],
            x: [star.x, star.x + 8, star.x - 8, star.x],
            rotate: [star.rotationOffset, star.rotationOffset + 360]
          }}
          transition={{
            duration: 20 + star.delay,
            repeat: Infinity,
            ease: "easeInOut",
            times: [0, 0.33, 0.66, 1],
            rotate: {
              duration: 30 + star.delay * 2,
              repeat: Infinity,
              ease: "linear",
              delay: star.delay
            }
          }}
          style={{
            width: star.size,
            position: "fixed",
            left: 0,
            top: 0,
            transform: `translate(${star.x}px, ${
              star.y +
              (star.layer === 1 ? scrollPosition * 0.03 : scrollPosition * 0.06)
            }px)`
          }}
          src="/card-assets/star-0.png"
          alt=""
          className="z-50"
        />
      ))}
    </div>
  );
};

export default function Landing() {
  return (
    <div className="font-rosarivo relative">
      <Textures type="paper-1" />
      <AnimatedStars />
      <div className="relative z-10">
        <Navbar />
        <Hero />
        <DailyDrawHero />
        <ReadingDemo />
        <GuideSection />
        <FeaturesSection />
        <PricingSection />
        <FAQSection />
        <Footer />
      </div>
    </div>
  );
}

// const Shadows = () => {
//   const videoRef = useRef<HTMLVideoElement>(null);
//   const [isLoaded, setIsLoaded] = useState(false);

//   useEffect(() => {
//     if (videoRef.current) {
//       videoRef.current.playbackRate = 0.75;
//     }
//   }, []);

//   return (
//     <div className="fixed inset-0 w-full h-full pointer-events-none z-0">
//       <motion.div
//         initial={{ opacity: 0 }}
//         animate={{ opacity: isLoaded ? 1 : 0 }}
//         transition={{ duration: 1.5 }}
//       >
//         <video
//           ref={videoRef}
//           autoPlay
//           muted
//           loop
//           playsInline
//           onLoadedData={() => setIsLoaded(true)}
//           className="absolute inset-0 w-full h-full object-cover mix-blend-color-dodge opacity-5 z-[100]"
//           style={{ filter: "contrast(1.2) brightness(1)" }}
//         >
//           <source src="/shadows.mp4" type="video/mp4" />
//         </video>
//       </motion.div>
//     </div>
//   );
// };

const GuideSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  const [selectedGuide, setSelectedGuide] = useState<number | null>(2);
  const [playingGuideId, setPlayingGuideId] = useState<number | null>(null);

  const handleGuidePlayStateChange = (guideId: number, isPlaying: boolean) => {
    setPlayingGuideId(isPlaying ? guideId : null);
  };

  return (
    <div ref={ref} id="pick-your-guide" className="bg-primary relative">
      <Textures type="paper-1" />
      <TornDivider className="bottom-36" />
      <motion.div
        className="mx-auto max-w-3xl text-primary-foreground text-center pt-24 mt-16 relative"
        initial={{ opacity: 0, y: 20 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <div className="w-full flex justify-center mt-12">
          {/* Rotating moon in a loop */}
          <motion.img
            src="/card-assets/wheel-of-fortune.png"
            alt="Wheel of Fortune"
            initial={{ opacity: 0, y: -10 }}
            animate={
              isInView
                ? {
                    opacity: 1,
                    rotate: [0, 360, 0]
                  }
                : { opacity: 0, y: -10 }
            }
            transition={{
              opacity: { duration: 1, delay: 1 },
              rotate: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 60,
                ease: "easeInOut"
              }
            }}
            className="w-24 h-24 object-contain -top-6 relative origin-center"
          />
        </div>
        <h2 className="text-3xl text font-bold tracking-tight leading-loose text-primary-foreground sm:text-4xl sm:leading-[3.5rem]">
          Pick The Guide <br />
          That's Right for You
        </h2>
        <p className="mt-2 text-lg leading-8 text-primary-foreground font-rosarivo mx-auto max-w-[450px]">
          Choose your personal guide for the journey ahead. Each brings their
          own unique approach to Tarot. <br />
          You can change your guide at any time.
        </p>
      </motion.div>
      <motion.div
        className="container px-4 grid grid-cols-4 gap-10 pt-32 relative"
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              staggerChildren: 0.1
            }
          }
        }}
      >
        {/* <div className="absolute w-[300vw] left-[-100vw] inset-0 bg-gradient-to-b from-transparent to-yellow-500/30 h-[200px] top-0" /> */}
        {/* <motion.div
          className="absolute w-[300vw] left-[-100vw] inset-0 bg-gradient-to-b from-transparent to-primary-foreground/30 h-[50px] top-36 border-b border-primary-foreground/50"
          initial={{ opacity: 0.3 }}
          animate={{
            opacity: [0.3, 0.5, 0.3]
          }}
          transition={{
            opacity: {
              duration: 4,
              ease: "easeInOut",
              repeat: Infinity,
              repeatType: "mirror"
            }
          }}
        /> */}
        {guides.map((guide, index) => (
          <motion.div
            key={guide.id}
            variants={{
              hidden: {
                opacity: 0,
                y: 40
              },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.8,
                  ease: "easeOut"
                }
              }
            }}
          >
            <GuideCard
              index={index}
              guide={guide}
              isOtherGuidePlaying={
                playingGuideId !== null && playingGuideId !== guide.id
              }
              onPlayStateChange={(isPlaying) =>
                handleGuidePlayStateChange(guide.id, isPlaying)
              }
            />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

const GuideCard = ({
  guide,
  index,
  isOtherGuidePlaying,
  onPlayStateChange
}: {
  index: number;
  guide: Guide;
  isOtherGuidePlaying: boolean;
  onPlayStateChange: (isPlaying: boolean) => void;
}) => {
  const [randomNumber] = useState(Math.random());
  const [isPlaying, setIsPlaying] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    audioRef.current = new Audio(guide.audio);
    audioRef.current.loop = false;

    const handleEnded = () => {
      setIsPlaying(false);
    };

    audioRef.current.addEventListener("ended", handleEnded);

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("ended", handleEnded);
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [guide.audio]);

  useEffect(() => {
    if (isOtherGuidePlaying && isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
      onPlayStateChange(false);
    }
  }, [isOtherGuidePlaying]);

  return (
    <div ref={ref} className="relative">
      <div className="flex flex-col items-center z-10 justify-center relative">
        <div className="flex items-center justify-center relative">
          {/* Rotating Background Blob */}
          <motion.div
            className="w-48 h-48 bg-primary-foreground left-0 top-8 -z-10 absolute"
            initial={{ opacity: 0 }}
            animate={{
              opacity: [0.04, 0.06, 0.04],
              scale: [0.8, 1.1, 0.8],
              borderRadius: [
                "30% 70% 70% 30% / 30% 30% 70% 70%",
                "60% 40% 30% 70% / 60% 30% 70% 40%",
                "30% 60% 70% 40% / 50% 60% 30% 60%",
                "30% 70% 70% 30% / 30% 30% 70% 70%"
              ]
            }}
            transition={{
              duration: 16,
              repeat: Infinity,
              repeatType: "mirror",
              ease: "easeInOut",
              delay: index * 3,
              opacity: {
                repeat: 1,
                duration: 8
              }
            }}
          />
          {/* Guide image */}
          <motion.div
            className={`w-36 aspect-[9/16] z-50 inline-block bg-primary-dark border-4 border-primary-dark rounded-lg shadow-md overflow-hidden bg-[length:105%_105%] bg-center`}
            initial={{ opacity: 0 }}
            style={{
              backgroundImage: `url(${guide.image})`
            }}
            animate={{
              opacity: 1,
              y: [-2, 2, -2],
              x: [-1, 1, -1],
              rotate: [
                -1 + randomNumber * -2, // Start with random negative rotation
                1 + randomNumber * 2, // Random positive rotation
                -1 + randomNumber * -2 // Start with random negative different random
              ]
            }}
            transition={{
              opacity: { duration: 0.5, delay: index * 0.2 },
              y: {
                repeat: Infinity,
                duration: 8,
                ease: "easeInOut",
                delay: index * 2.3
              },
              x: {
                repeat: Infinity,
                duration: 7,
                ease: "easeInOut",
                delay: index * 2.6
              },
              rotate: {
                repeat: Infinity,
                duration: 10,
                ease: "easeInOut",
                delay: index * 1.9
              }
            }}
          />
        </div>
        {/* Description */}
        <motion.div
          initial={{ opacity: 0, rotate: 0 }}
          animate={
            isInView ? { opacity: 1, rotate: -2 + randomNumber * 5 } : {}
          }
          transition={{ duration: 0.8, delay: index * 0.2 }}
          className="flex flex-col mx-4 bg-background border-t-0 border border-primary-foreground/10 py-6 px-6 shadow-xl rounded-b-xl relative overflow-hidden group cursor-pointer"
        >
          <Textures type="paper-1" />
          <h3 className="text font-bold tracking-tight leading-loose text-primary-foreground sm:text-2xl sm:leading-[3.5rem] relative mb-4 text-center">
            {guide.name}
          </h3>
          <AnimatedText text={guide.slogan} />
          <div className="relative mb-2">
            <ul className="list-disc list-outside pl-2 text-primary-foreground leading-6 space-y-4 text-sm">
              {guide.bulletPoints.map((point: string) => (
                <li key={point}>{point}</li>
              ))}
            </ul>
          </div>
          {/* {isSelected ? (
            <button
              className="uppercase text-yellow-600/75 text-2xl mt-4 flex items-center justify-center"
              onClick={() => setSelectedGuide(index)}
            >
              <span className="border-2 border-yellow-600/75 rounded-full px-4 pt-1">
                SELECTED
              </span>
            </button>
          ) : (
            <button
              onClick={() => setSelectedGuide(index)}
              className="uppercase group-hover:text-yellow-600/75 text-primary-foreground/50 text-2xl mt-4 flex items-center justify-center"
            >
              <span className="border-2 group-hover:border-yellow-600/75 border-primary-foreground/50 rounded-full px-4 pt-1">
                SELECT
              </span>
            </button>
          )} */}
        </motion.div>
        <AnimatePresence mode="wait">
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{
              opacity: [0.4, 0.6, 0.4],
              scale: [1.2, 1.25, 1.2]
            }}
            exit={{
              opacity: 0,
              scale: 1,
              transition: { duration: 0.3 }
            }}
            transition={{
              opacity: {
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
                repeatType: "mirror",
                delay: index * 1.5
              },
              scale: {
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
                repeatType: "mirror"
              }
            }}
            className="absolute blur-[25px] rounded-full inset-0 w-[80%] left-[10%] top-1/2 h-1/2 -z-10 bg-gradient-to-t from-orange-500/50 via-yellow-500/50 to-transparent"
          />
        </AnimatePresence>
      </div>
    </div>
  );
};

const AnimatedText = ({ text }: { text: string }) => {
  // Split text into words while preserving spaces
  const slogan = `"` + text + `"`;
  const words = slogan.split(/(\s+)/).filter(Boolean);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        visible: {
          transition: {
            staggerChildren: 0.1
          }
        }
      }}
      className="relative text-lg text-orange-950/90 font-homemade mb-7 text-center"
    >
      {words.map((word, index) => (
        <motion.span
          key={index}
          variants={{
            hidden: {
              opacity: 0,
              y: 5
            },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                ease: "easeOut",
                duration: 0.6
              }
            }
          }}
          className="inline-block whitespace-pre"
        >
          {word}
        </motion.span>
      ))}
    </motion.div>
  );
};
