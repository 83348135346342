import { motion } from "framer-motion";

export const CardLoader = ({
  loadingText = "Loading your cards..."
}: {
  loadingText?: string;
}) => {
  return (
    <div className="absolute inset-0 pointer-events-none flex items-center justify-center h-[calc(100vh-100px)]">
      <div className="text-center">
        <div className="flex justify-center items-end space-x-2 mb-4">
          {[0, 1, 2, 3, 4].map((index) => (
            <motion.div
              key={index}
              className="w-4 aspect-[400/700] bg-orange-950/80 rounded-sm"
              animate={{
                scaleY: [0.4, 1, 0.4],
                transition: {
                  duration: 1.2,
                  repeat: Infinity,
                  ease: "easeInOut",
                  delay: index * 0.1
                }
              }}
            />
          ))}
        </div>
        <p className="text-orange-950">{loadingText}</p>
      </div>
    </div>
  );
};
