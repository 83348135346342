import { cn } from "@/lib/utils";

export const Textures = ({
  type,
  className
}: {
  type: "paper-1" | "paper-2";
  className?: string;
}) => {
  if (type === "paper-1") {
    return (
      <div
        id="paper-texture"
        className={cn(
          "h-full w-full absolute inset-0 bg-background bg-[url('/paper-texture-seamless.jpg')] bg-center bg-repeat bg-blend-multiply bg-[length:300px_300px] opacity-50",
          className
        )}
      />
    );
  } else if (type === "paper-2") {
    return (
      <div
        id="fabric-texture"
        className={cn(
          "absolute inset-0 bg-[url('/paper-2.jpg')] bg-center bg-repeat bg-[length:300px_300px] bg-blend-multiply opacity-30 bg-background",
          className
        )}
      />
    );
  }
};
