import { useState } from "react";
import Layout from "@/components/layout";
import { Input } from "@/components/ui/input";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { redeemLicense } from "@/utils/api";
import { useToast } from "@/components/ui/use-toast";
import { Check } from "lucide-react";
import { PushableButton } from "@/components/PushableButton";

const Redeem = () => {
  const [licenseKey, setLicenseKey] = useState("");
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const redeemMutation = useMutation({
    mutationFn: redeemLicense,
    onSuccess: () => {
      toast({
        title: "Success!",
        description: "Your license key has been redeemed successfully."
      });
      // Invalidate any queries that might show user credits
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
      setLicenseKey("");
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description:
          error.response?.data?.error || "Failed to redeem license key",
        variant: "destructive"
      });
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!licenseKey.trim()) {
      toast({
        title: "Error",
        description: "Please enter a license key",
        variant: "destructive"
      });
      return;
    }
    redeemMutation.mutate(licenseKey);
  };

  const includedFeatures = [
    "Get personalized readings",
    "Learn about each Tarot card",
    "Choose your own guide",
    "No expiration, use anytime"
  ];

  return (
    <Layout>
      <div className="mx-auto max-w-screen-xl">
        <div className="rounded-3xl ring-1 ring-primary-darker lg:mx-0 lg:flex items-center">
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:shrink-0">
            <div className="rounded-2xl bg-primary-dark py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="mt-6 items-baseline flex justify-center gap-x-2">
                  <span className="text-4xl md:text-5xl font-semibold tracking-tight text-primary-foreground">
                    $11 / 1111
                  </span>
                  <span className="text-sm md:text-base font-semibold tracking-wide text-primary-foreground-muted ml-2">
                    Messages
                  </span>
                </p>
                <div className="mt-8">
                  <PushableButton
                    text="Buy Credits"
                    href="https://youruniquetarot.gumroad.com/l/credits"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-3xl font-semibold tracking-tight text-primary-foreground">
              Unlock The Full Tarot Experience
            </h3>
            <p className="mt-4 text-base/7 text-primary-foreground">
              Dive deeper into your tarot readings with personalized guidance.
              No subscriptions, no recurring fees.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm/6 font-semibold text-primary-foreground">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-primary-darker" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm/6 text-primary-foreground sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <Check
                    aria-hidden="true"
                    className="h-6 w-5 flex-none text-rose-500"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="rounded-3xl ring-1 ring-primary-darker mt-8 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-3xl font-semibold tracking-tight text-primary-foreground">
              Redeem Your Credits
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4 mt-6">
              <div className="space-y-2">
                <Input
                  type="text"
                  placeholder="Enter your key"
                  value={licenseKey}
                  onChange={(e) => setLicenseKey(e.target.value)}
                  disabled={redeemMutation.isPending}
                />
              </div>
              <PushableButton
                type="submit"
                disabled={redeemMutation.isPending}
                text={
                  redeemMutation.isPending ? "Redeeming..." : "Redeem Credits"
                }
                className="w-full"
              />
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Redeem;
