import { create } from "zustand";

interface BreadcrumbState {
  dynamicSegments: Record<string, string>;
  setDynamicSegment: (path: string, title: string) => void;
  clearDynamicSegments: () => void;
}

export const useBreadcrumbStore = create<BreadcrumbState>((set) => ({
  dynamicSegments: {},
  setDynamicSegment: (path, title) =>
    set((state) => ({
      dynamicSegments: { ...state.dynamicSegments, [path]: title }
    })),
  clearDynamicSegments: () => set({ dynamicSegments: {} })
}));
