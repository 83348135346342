import * as React from "react";
import { useMediaQuery } from "@/hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from "@/components/ui/drawer";
import { Button } from "@/components/ui/button";
import { Card as CardType } from "@/types/Card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";

interface CardSelectionDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  cards: CardType[];
  onCardSelect: (cardId: number) => void;
}

export function CardSelectionDialog({
  open,
  onOpenChange,
  cards,
  onCardSelect
}: CardSelectionDialogProps) {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [searchQuery, setSearchQuery] = React.useState("");
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const handleOpenAutoFocus = (e: Event) => {
    e.preventDefault();
    searchInputRef.current?.focus();
  };

  const filteredCards = React.useMemo(() => {
    if (!cards) return [];
    return cards
      .filter(
        (card) =>
          card.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          card.cardNumber.toString().includes(searchQuery)
      )
      .sort((a, b) => a.id - b.id);
  }, [cards, searchQuery]);

  const CardGrid = () => (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      {filteredCards.map((card) => (
        <div
          key={card.id}
          className="flex relative cursor-pointer overflow-hidden rounded-lg items-center"
          onClick={() => {
            onCardSelect(card.id);
            onOpenChange(false);
          }}
        >
          <img
            src={card.imageUrl}
            alt={card.name}
            className="w-12 md:w-full rounded-lg transition-transform duration-300 hover:scale-110"
          />
          <div className="w-full flex items-center md:absolute md:inset-0 bg-background-dark border border-primary-foreground/20 md:bg-black/50 text-primary-foreground md:text-white p-2 md:rounded-b-lg rounded-r-md">
            <p className="text-sm text-left ml-4">
              {card.cardNumber} - {card.name}
            </p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        onOpenAutoFocus={handleOpenAutoFocus}
        className="sm:max-w-[800px] h-[80vh]"
      >
        <DialogHeader>
          <DialogTitle className="text-primary-foreground mt-2">
            Select Your Card
          </DialogTitle>
        </DialogHeader>

        <Input
          ref={searchInputRef}
          placeholder="Search cards..."
          value={searchQuery}
          className="text-primary-foreground"
          onChange={(e) => setSearchQuery(e.target.value)}
          autoComplete="off"
        />
        <ScrollArea className="h-[calc(80vh-180px)]">
          <CardGrid />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
