import Layout from "@/components/layout";
import { useState } from "react";
import { PushableButton } from "@/components/PushableButton";
import { useMutation } from "@tanstack/react-query";
import { sendFeedback } from "@/utils/api";
import { toast } from "@/components/ui/use-toast";

export default function Contact() {
  const [message, setMessage] = useState("");

  const feedbackMutation = useMutation({
    mutationFn: sendFeedback,
    onSuccess: () => {
      toast({
        title: "Feedback Sent",
        description: "Thank you for your feedback!"
      });
      setMessage("");
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to send feedback. Please try again.",
        variant: "destructive"
      });
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim()) return;
    feedbackMutation.mutate(message);
  };

  return (
    <Layout>
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold text-primary-foreground">Feedback</h1>
        <form onSubmit={handleSubmit} className="mt-8 space-y-4">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full h-32 p-4 rounded-lg border border-primary-foreground/20 bg-background text-primary-foreground resize-none"
            placeholder="Write your feedback here..."
          />
          <PushableButton
            text={feedbackMutation.isPending ? "Sending..." : "Send Feedback"}
            type="submit"
            disabled={feedbackMutation.isPending || !message.trim()}
          />
        </form>
      </div>
    </Layout>
  );
}
