import { cn } from "@/lib/utils";
import { motion } from "framer-motion";

interface TarotCardProps {
  number: string;
  name: string;
  image: string;
  index: number;
  cardWidth: "large" | "small";
  animate?: boolean;
  showShadow?: boolean;
}

const cardVariants = {
  hidden: (index: number) => ({
    y: 50,
    scale: index === 1 ? 0.9 : 0.9,
    x: 0,
    rotate: 0,
    rotateY: 0,
    boxShadow: "var(--shadow-elevation-medium)"
  }),
  visible: (index: number) => ({
    y: index === 1 ? 0 : -25,
    scale: 1,
    x: index === 0 ? -125 : index === 2 ? 125 : 0,
    rotate: index === 0 ? -5 : index === 2 ? 5 : 0,
    rotateY: index === 0 ? 15 : index === 2 ? -15 : 0,
    boxShadow:
      index === 1
        ? "var(--shadow-elevation-medium)"
        : "var(--shadow-elevation-high)",
    transition: {
      duration: 0.5,
      delay: index === 1 ? 0 : 0.3,
      ease: "easeOut",
      boxShadow: { delay: 0.2, duration: 0.3 }
    }
  }),
  animate: (index: number) => ({
    y: [index === 1 ? 0 : -25, index === 1 ? -10 : -35, index === 1 ? 0 : -25],
    rotate: [
      index === 0 ? -2 : index === 2 ? 2 : 0,
      index === 0 ? -5 : index === 2 ? 5 : 1,
      index === 0 ? -2 : index === 2 ? 2 : 0
    ],
    rotateY: [
      index === 0 ? 3 : index === 2 ? -3 : 0,
      index === 0 ? 5 : index === 2 ? -5 : 0,
      index === 0 ? 3 : index === 2 ? -3 : 0
    ],
    transition: {
      y: {
        repeat: Infinity,
        duration: 2,
        ease: "easeInOut",
        delay: index === 0 ? 0.5 : index === 1 ? 0 : index === 2 ? 1 : 0
      },
      rotate: {
        repeat: Infinity,
        duration: 3,
        ease: "easeInOut",
        delay: index === 0 ? 0.7 : index === 1 ? 0 : index === 2 ? 1.2 : 0
      },
      rotateY: {
        repeat: Infinity,
        duration: 3,
        ease: "easeInOut",
        delay: index === 0 ? 0.9 : index === 1 ? 0 : index === 2 ? 1.4 : 0
      }
    }
  })
};

export const TarotCard: React.FC<TarotCardProps> = ({
  number,
  name,
  image,
  index,
  cardWidth,
  animate = true,
  showShadow = true
}) => {
  const CardComponent = animate ? motion.div : "div";

  return (
    <CardComponent
      className={cn(
        "rounded-lg lg:rounded-2xl p-4 overflow-hidden bg-[#f4e7d8] mx-2 border border-black border-opacity-40",
        showShadow ? (index === 1 ? "z-50" : "z-10") : "",
        animate ? "absolute" : ""
      )}
      variants={animate ? cardVariants : undefined}
      custom={index}
      initial={animate ? "hidden" : undefined}
      animate={animate ? ["visible", "animate"] : undefined}
      style={{
        width:
          cardWidth === "large"
            ? "250px"
            : cardWidth === "small"
            ? "150px"
            : "200px",
        transformStyle: "preserve-3d"
      }}
    >
      <div className="relative">
        <div className="flex items-center absolute w-full justify-center z-10">
          <span className="absolute flex items-center justify-center font-rosarivo text-xs border border-t-0 border-black bg-[#f4e7d8] font-left-0 mx-4 w-8 h-4 mt-4 rounded-br-full rounded-bl-full">
            <span className="z-10 text-xs -mt-2">{number}</span>
          </span>
        </div>
        <img
          src={image}
          className="object-cover border border-black"
          alt={name}
        />
        <div className="w-full flex justify-center text-center">
          <div
            className={cn(
              "bg-[#f4e7d8] border border-t-0 border-black w-full",
              cardWidth === "small" ? "px-2" : "px-2 py-1"
            )}
          >
            <span
              className={cn("font-rosarivo", {
                "text-xs": cardWidth === "small",
                "text-md": cardWidth === "large"
              })}
            >
              {name}
            </span>
          </div>
        </div>
      </div>
      <div className="absolute z-50 inset-0 bg-[url('/texture.jpg')] bg-cover mix-blend-multiply"></div>
    </CardComponent>
  );
};
