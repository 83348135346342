import { ArrowDown } from "lucide-react";
import BlurBackground from "./BlurBackground";
import { PushableButton } from "./PushableButton";
import DailyDrawHero from "./DailyDrawHero";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { AnimatedFlower } from "./AnimatedFlower";

export default function Hero() {
  const text = "Your Personal AI Tarot Guide";
  const letterVariants = {
    hidden: { opacity: 0, y: 40, rotate: 10 },
    visible: { opacity: 1, y: 0, rotate: 0 }
  };

  return (
    <div>
      {/* <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ duration: 4, delay: 1.5 }}
        >
          <BlurBackground />
        </motion.div>
      </AnimatePresence> */}
      <div className="mx-auto max-w-7xl my-8 sm:pb-16 lg:px-8 lg:mt-28 relative text-center flex flex-col items-center">
        <h1 className="text-3xl mt-6 font-bold tracking-tight flex leading-loose text-primary-foreground sm:text-5xl sm:leading-[5rem] relative">
          <div className="absolute w-full flex justify-center">
            {/* Rotating moon in a loop */}
            <motion.img
              src="/card-assets/moon.png"
              alt="Moon"
              initial={{ opacity: 0, y: -20 }}
              animate={{
                opacity: 1,
                y: [-2, 2, -2],
                rotate: [-3, 9, -3]
              }}
              transition={{
                opacity: { duration: 1, delay: 4 },
                y: {
                  repeat: Infinity,
                  duration: 4,
                  ease: "easeInOut"
                },
                rotate: {
                  repeat: Infinity,
                  duration: 6,
                  ease: "easeInOut"
                }
              }}
              className="w-12 h-12 object-contain relative -top-24 origin-center"
            />
          </div>
          {text.split("").map((char, index) => (
            <motion.span
              key={index}
              variants={letterVariants}
              initial="hidden"
              animate="visible"
              transition={{
                duration: 0.5,
                delay: index * 0.02, // Stagger effect
                ease: "easeOut"
              }}
            >
              {char === " " ? "\u00A0" : char}
            </motion.span>
          ))}
        </h1>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 1, delay: 1 }}
            className="flex flex-col items-center"
          >
            <p className="mt-2 text-lg leading-8 text-primary-foreground font-rosarivo pl-2 max-w-3xl mx-auto">
              Deepen your tarot practice through daily readings and in-depth
              conversations. Your personal guide helps you learn the cards
              deeply while revealing insights unique to your journey, making
              each reading truly yours.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <PushableButton
                text="Try it for free"
                href="/register"
                size="small"
              />
              <a
                href="#daily-draw-hero"
                onClick={(e) => {
                  e.preventDefault();
                  document.querySelector("#daily-draw-hero")?.scrollIntoView({
                    behavior: "smooth"
                  });
                }}
                className="text-sm font-semibold leading-6 text-primary-foreground flex items-center gap-x-2"
              >
                How it works{" "}
                <span aria-hidden="true">
                  <ArrowDown className="w-4 h-4" />
                </span>
              </a>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}
