import Layout from "@/components/layout";
import { useUserStore } from "@/stores/useUserStore";
import { Reading } from "@/types/api";
import { checkIfDailyReadingExists, getWeeklyReadings } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { Card } from "@/pages/ReadingDetail";
import { Card as CardType } from "@/types/Card";
import { Skeleton } from "@/components/ui/skeleton";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "@/components/ui/use-toast";
import {
  format,
  subDays,
  isSameDay,
  startOfWeek,
  addDays,
  isBefore,
  endOfDay,
  isAfter
} from "date-fns";
import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";
import { Plus } from "lucide-react";

const getTimeOfDay = () => {
  const hour = new Date().getHours();

  if (hour < 12) return "Morning";
  if (hour < 17) return "Afternoon";
  return "Evening";
};

interface WeeklyReading {
  id: number;
  createdAt: string;
  card: {
    id: number;
    name: string;
    imageUrl: string;
  };
}

interface DayReading {
  date: string;
  reading: WeeklyReading | undefined;
  isInThePast: boolean;
  isToday: boolean;
}

const Dashboard = () => {
  const location = useLocation();
  const timeOfDay = getTimeOfDay();

  // Handle navigation state messages
  useEffect(() => {
    if (location.state?.message) {
      toast({
        description: location.state.message,
        duration: 3000
      });
      // Clear the message from location state to prevent showing on refresh
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const {
    data: dailyReading,
    isLoading,
    isFetching
  } = useQuery<Reading | null, Error>({
    queryKey: ["dailyReading"],
    queryFn: checkIfDailyReadingExists,
    staleTime: 0,
    gcTime: 1000 * 60 * 60
  });

  const { data: weeklyReadings } = useQuery<WeeklyReading[]>({
    queryKey: ["weeklyReadings"],
    queryFn: getWeeklyReadings
  });

  const { user } = useUserStore();

  const getDayName = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  const getCurrentWeekDays = (): DayReading[] => {
    const days: DayReading[] = [];
    const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const today = new Date();

    for (let i = 0; i < 7; i++) {
      const date = addDays(startOfCurrentWeek, i);
      const reading = weeklyReadings?.find((r) =>
        isSameDay(new Date(r.createdAt), date)
      );
      days.push({
        date: format(date, "yyyy-MM-dd"),
        reading,
        isInThePast: isBefore(date, today) && !isSameDay(date, today),
        isToday: isSameDay(date, today)
      });
    }
    return days;
  };

  const days = getCurrentWeekDays();

  return (
    <Layout>
      <div className="mx-auto max-w-screen-2xl">
        {isLoading && !isFetching ? (
          <Skeleton className="w-full h-[234px]" />
        ) : (
          <>
            <motion.a
              href={dailyReading ? `/readings/${dailyReading.id}` : "/daily"}
              whileHover="hover"
              className="inline-block w-full bg-gradient-to-br from-orange-500/10 via-yellow-500/10 to-pink-500/10 px-12 py-20 rounded-sm md:rounded-lg border border-primary-foreground/10 overflow-hidden relative
            hover:bg-gradient-to-br hover:from-orange-500/20 hover:via-yellow-500/20 hover:to-pink-500/20 
            hover:border-primary-foreground/20 
            transition-all duration-300 ease-in-out"
            >
              <h1 className="text-3xl font-bold text-white lg:text-primary-foreground relative z-20">
                Good {timeOfDay} {user?.name},
              </h1>
              {dailyReading ? (
                <>
                  <span className="text-2xl lg:text-lg mt-2 inline-block font-bold text-white lg:text-primary-foreground relative z-20">
                    Todays card is {dailyReading?.card?.name}
                  </span>
                  <div className="block lg:hidden absolute inset-0 bg-black/40 z-10" />
                  <motion.div
                    className="absolute right-1/4 -top-20"
                    initial={{ rotate: 12, scale: 1 }}
                    variants={{
                      hover: {
                        rotate: 8,
                        scale: 1.02,
                        transition: {
                          type: "spring",
                          stiffness: 400,
                          damping: 25
                        }
                      }
                    }}
                  >
                    <Card card={dailyReading.card} />
                  </motion.div>
                </>
              ) : (
                <>
                  <div className="block lg:hidden absolute inset-0 bg-black/40 z-10" />
                  <span className="z-10 relative text-lg mt-2 inline-block font-bold text-white lg:text-primary-foreground">
                    Ready to draw your daily card?
                  </span>
                  <motion.div
                    className="aspect-[9/16] rounded-sm md:rounded-lg right-1/4 flex items-center justify-center rotate-12 top-10 bg-primary-darker border border-primary-foreground/10 absolute w-48"
                    initial={{ scale: 0, rotate: -180 }}
                    animate={{ scale: 1, rotate: 12 }}
                    variants={{
                      hover: {
                        scale: 1.05,
                        rotate: 20,
                        transition: {
                          type: "spring",
                          stiffness: 400,
                          damping: 10
                        }
                      }
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 260,
                      damping: 20,
                      duration: 0.6
                    }}
                  >
                    <span className="absolute top-12 font-mono text-8xl text-primary-foreground">
                      ?
                    </span>
                  </motion.div>
                </>
              )}
            </motion.a>

            <div className="mt-6 md:mt-16">
              <h2 className="text-xl font-bold text-primary-foreground mb-4 text-center">
                This Week's Readings
              </h2>
              <div
                className="grid grid-cols-7 gap-1 md:gap-2 w-full bg-gradient-to-br from-orange-500/10 via-yellow-500/10 to-pink-500/10 p-1 md:p-4 rounded-sm md:rounded-lg border border-primary-foreground/10 overflow-hidden relative
            hover:bg-gradient-to-br"
              >
                {days.map((day) => (
                  <div key={day.date} className="aspect-square relative">
                    {day.reading ? (
                      <Link
                        to={`/readings/${day.reading.id}`}
                        className="block w-full aspect-[375/629] relative group overflow-hidden rounded-sm md:rounded-lg"
                      >
                        <img
                          src={day.reading.card.imageUrl}
                          alt={day.reading.card.name}
                          className="w-full aspect-[375/629] object-cover rounded-sm md:rounded-lg transition-transform duration-200 group-hover:scale-105"
                        />
                        <div className="z-10 absolute inset-0 bg-gradient-to-t from-black/50 to-transparent transition-opacity duration-200 rounded-sm md:rounded-lg hidden md:flex items-center justify-end flex-col gap-1 pb-4">
                          <span className="text-white md:text-xs lg:text-sm xl:text-lg text-center">
                            {day.reading.card.name}
                          </span>
                          <span className="text-white md:text-xs lg:text-sm xl:text-lg">
                            {getDayName(day.date)}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      <div
                        className={cn(
                          "w-full relative aspect-[375/629] rounded-sm md:rounded-lg border border-dashed border-primary-foreground/20 flex pb-4 justify-center items-end",
                          {
                            "bg-primary-darker": day.isInThePast,
                            "bg-primary": !day.isInThePast
                          }
                        )}
                      >
                        {day.isToday && (
                          <Link
                            to={`/daily`}
                            className="lg:flex absolute inset-0 hidden items-center justify-center text-primary-foreground/40 text-xs xl:text-sm text-center cursor-pointer hover:bg-black/5 rounded-lg transition-colors duration-200 flex-col gap-1"
                          >
                            <Plus className="w-4 h-4" />
                            <span>
                              Draw <br /> Daily Card
                            </span>
                          </Link>
                        )}
                        <span className="hidden md:block text-primary-foreground/40 md:text-xs lg:text-sm xl:text-lg ">
                          {getDayName(day.date)}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Dashboard;
