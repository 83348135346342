import { create } from "zustand";
import { persist } from "zustand/middleware";
import { User } from "@/types/api";

interface UserStore {
  user: User | null;
  setUser: (user: User | null) => void;
  clearUser: () => void;
  updateCredits: (
    messageCredits?: number,
    cardGenerationCredits?: number
  ) => void;
}

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      user: null,
      setUser: (user) => {
        set({ user });
      },
      clearUser: () => {
        set({ user: null });
      },
      updateCredits: (messageCredits) =>
        set((state) => {
          console.log("Updating credits:", {
            messageCredits
          });
          return {
            user: state.user
              ? {
                  ...state.user,
                  ...(messageCredits !== undefined && { messageCredits })
                }
              : null
          };
        })
    }),
    {
      name: "user-storage",
      partialize: (state) => ({ user: state.user })
    }
  )
);
