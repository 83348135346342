import Logo from "./Logo";

const navigation = {
  company: [
    { name: "About", href: "/About" },
    { name: "Blog", href: "/Blog" }
    // { name: "Press", href: "#" }
  ],
  legal: [
    { name: "Terms of Service", href: "/terms-of-service" },
    { name: "Privacy  Policy", href: "/privacy-policy" },
    { name: "Refund Policy", href: "/refund-policy" }
  ]
};

export default function Footer() {
  return (
    <footer aria-labelledby="footer-heading" className="font-rosarivo mt-32">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div
        // className="grid grid-cols-1 md:grid-cols-1 gap-8 md:gap-16 justify-between"
        >
          <div className="space-y-8 mb-36 flex flex-col items-center text-center relative">
            <a
              href="/"
              className="w-full items-center justify-center p-1.5 flex space-x-4"
            >
              <Logo textSize="large" />
            </a>

            <p className="text-sm leading-6 text-primary-foreground max-w-[290px]">
              Helping you explore and embrace the story of your life to make it
              more meaningful, one card at a time.
            </p>

            <p className="text-sm leading-6 text-primary-foreground max-w-[290px]">
              Illustrations by <br />
              <a
                href="https://en.wikipedia.org/wiki/Pamela_Colman_Smith"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Pamela Colman Smith
              </a>
              .
            </p>
            <p className="text-sm leading-6 text-primary-foreground max-w-[290px]">
              Soulfully crafted by <br />
              <a
                href="https://www.twitter.com/aiiidenfreeman"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Aiden Freeman
              </a>
              .
            </p>
            <div className="absolute -bottom-16 flex items-center justify-center">
              {/* <span className="absolute text-[4rem] text-yellow-500 bg-blend-screen opacity-20 -ml-2">
                魂
              </span>*/}
              <span className="absolute text-3xl text-primary-foreground bg-blend-screen-dodge opacity-20">
                魂
              </span>
              <span className="absolute text-3xl text-black/50 blur-[1px] bg-blend-screen-dodge opacity-20">
                魂
              </span>
            </div>
          </div>

          {/* <div className="pl-12">
            <h3 className="text-sm font-semibold leading-6 text-gray-900">
              Your Unique Tarot
            </h3>
            <ul role="list" className="mt-6 space-y-4">
              {navigation.company.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div> */}
          {/* Add one div by 1 column */}
          {/* <div>
            <h3 className="text-sm font-semibold leading-6 text-primary-foreground">
              Legal
            </h3>
            <ul role="list" className="mt-6 space-y-4">
              {navigation.legal.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className="text-sm leading-6 text-primary-foreground"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div> */}
        </div>

        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-primary-foreground text-center">
            &copy; {new Date().getFullYear()} Your Unique Tarot, Inc. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
