import React from "react";
import { DynamicBreadcrumb } from "@/components/Breadcrumb";
import { AppSidebar } from "@/components/app-sidebar";
import { Separator } from "@/components/ui/separator";
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger
} from "@/components/ui/sidebar";
import { cn } from "@/lib/utils";
import { useUserStore } from "@/stores/useUserStore";
import { Textures } from "./Textures";

interface LayoutProps {
  children: React.ReactNode;
  disablePadding?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  disablePadding = false
}) => {
  const { user } = useUserStore();
  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset>
        <Textures type="paper-1" />
        <header className="flex h-12 md:h-16 shrink-0 items-center justify-between gap-2 relative z-10">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <DynamicBreadcrumb />
          </div>
          <div className="px-4">
            <a
              className="py-0.5 px-4 bg-primary-dark text-primary-foreground-muted rounded-full border border-primary-foreground/10"
              href="/credits"
            >
              <span className="text-xs font-sans uppercase">
                {user?.messageCredits} Credits
              </span>
            </a>
          </div>
        </header>
        <div
          className={cn("relative z-10 flex-1", {
            "p-4": !disablePadding
          })}
        >
          {children}
        </div>
      </SidebarInset>
    </SidebarProvider>
  );

  // return (
  //   <SidebarProvider>
  //     <div
  //       className="fixed inset-0 w-full h-full bg-repeat opacity-30 pointer-events-none"
  //       style={{ backgroundImage: "url(/fabric.webp)" }}
  //     />

  //     <div className="flex min-h-screen relative z-10">
  //       {/* Sidebar */}
  //       <aside className="fixed top-0 left-0 z-50 h-full w-64 border-r bg-background-dark/95 backdrop-blur-sm">
  //         <div className="flex h-16 items-center border-b px-6">
  //           <Logo textSize="small" />
  //         </div>

  //         <div className="px-4 py-4">
  //           <nav className="space-y-2">
  //             {mainMenuItems.map((item) => (
  //               <Link
  //                 key={item.name}
  //                 to={item.path}
  //                 className={`flex items-center rounded-lg px-3 py-2 transition-colors ${
  //                   location.pathname.includes(item.path)
  //                     ? "bg-orange-950/10 text-orange-950"
  //                     : "text-orange-950/70 hover:bg-orange-950/5"
  //                 }`}
  //               >
  //                 <item.icon className="h-5 w-5 mr-3" />
  //                 <span>{item.name}</span>
  //               </Link>
  //             ))}
  //           </nav>

  //           <Separator className="my-4" />

  //           <nav className="space-y-2">
  //             {generalMenuItems.map((item) => (
  //               <Link
  //                 key={item.name}
  //                 to={item.path}
  //                 className="flex items-center rounded-lg px-3 py-2 text-orange-950/70 hover:bg-orange-950/5 transition-colors"
  //               >
  //                 <item.icon className="h-5 w-5 mr-3" />
  //                 <span>{item.name}</span>
  //               </Link>
  //             ))}

  //             <button
  //               onClick={handleLogout}
  //               className="flex w-full items-center rounded-lg px-3 py-2 text-orange-950/70 hover:bg-orange-950/5 transition-colors"
  //             >
  //               <LogOut className="h-5 w-5 mr-3" />
  //               <span>Logout</span>
  //             </button>
  //           </nav>
  //         </div>
  //       </aside>

  //       {/* Main Content */}
  //       <SidebarInset>
  //         <header className="flex h-16 shrink-0 items-center border-b bg-background-dark/95 backdrop-blur-sm">
  //           <div className="flex items-center gap-2 px-6">
  //             <SidebarTrigger />
  //             <Separator orientation="vertical" className="mx-4 h-6" />
  //             <Breadcrumb>
  //               <BreadcrumbList>
  //                 <BreadcrumbItem>
  //                   <BreadcrumbLink href="/">YourUniqueTarot</BreadcrumbLink>
  //                 </BreadcrumbItem>
  //                 <BreadcrumbItem>
  //                   <BreadcrumbLink>{currentPageName}</BreadcrumbLink>
  //                 </BreadcrumbItem>
  //               </BreadcrumbList>
  //             </Breadcrumb>
  //           </div>
  //         </header>

  //         <main className="flex-1 overflow-y-auto">{children}</main>
  //       </SidebarInset>
  //     </div>
  //   </SidebarProvider>
  // );
};

export default Layout;
